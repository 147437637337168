import { Checkbox, FormControl, FormControlLabel, FormHelperText } from "@material-ui/core"
import { Controller, UseFormReturn } from "react-hook-form"
import { ChangeEvent, ReactElement } from "react"
import { ReactComponent as Check } from "../../icon/check.svg"
import { ReactComponent as Cheked } from "../../icon/cheked.svg"

type Props = {
  form: any
  name: string
  label?: string | ReactElement
  disabled?: boolean
  required?: boolean
  onChange?(e: ChangeEvent<HTMLInputElement>, checked: boolean): void
}
const CheckboxInput: React.FC<Props> = ({
  form,
  name,
  required = false,
  label = "Список",
  disabled = false,
  onChange: change
}) => {
  const {
    register,
    control,
    formState: { errors, touchedFields, isSubmitted }
  }: UseFormReturn = form
  return (
    <FormControl>
      <FormControlLabel
        {...register(name, { required })}
        control={
          <Controller
            control={control}
            name={name}
            rules={{ required }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Checkbox
                onBlur={onBlur}
                inputRef={ref}
                onChange={(e, c) => {
                  onChange(e, c)
                  if (change) change(e, c)
                }}
                checked={value}
                icon={<Check />}
                checkedIcon={<Cheked />}
                disabled={disabled}
              />
            )}
          />
        }
        label={label}
      />

      <FormHelperText style={{ bottom: 4 }}>
        {errors[name] && (touchedFields[name] || isSubmitted) && "Подтвердите согласие"}
      </FormHelperText>
    </FormControl>
  )
}

export default CheckboxInput
