import ReactHtmlParser from "react-html-parser"

const russianRules = (s: string) => {
  if (s === undefined || s === null || s.length === 0) {
    return s
  }

  const words = ["к", "в", "о", "и", "с", "на", "за", "а", "не"]

  for (let i = 0; i < words.length; i++) {
    // eslint-disable-next-line no-param-reassign
    s = s.replace(new RegExp(`\\s${words[i]}\\s`, "g"), ` ${words[i]}&nbsp;`)
  }

  return ReactHtmlParser(s)
}

export default russianRules
