import { Route, useHistory } from "react-router-dom"
import { useEffect } from "react"
import { Box, Container, Grid } from "@material-ui/core"
import { useSelector } from "../../hooks"
import PageContainer from "../../page/layout/page-container"
// import { useSelector } from "../../hooks"

type Prop = {
  component: any
  key?: any
  rest?: any
  path: string
  exact?: boolean
}

const PrivateRoute: React.FC<Prop> = ({ component: Component, path, key, exact, ...rest }) => {
  const { loading, data } = useSelector((state) => state.authReducer)
  const { init, userStatus } = useSelector((state) => state.propsReducer)

  const history = useHistory()
  useEffect(() => {
    if (!data && !loading && init) {
      history.push("/?signin")
    }
  }, [loading, data, init])

  if (userStatus === "new" && history.location.pathname !== "/signout") {
    return (
      <PageContainer>
        <Container maxWidth={false}>
          <Grid container justify="center" spacing={4}>
            <Grid item xs={12} sm={7}>
              <Box pt={6} pb={6} textAlign="center">
                Ваша регистрационная анкета на проверке.
                <br /> Для завершения регистрации в течение 3-х рабочих дней с Вами свяжется менеджер.
                <Box pt={4}> Ожидайте, пожалуйста, звонка специалиста.</Box>
                <Box pt={4}> После успешной модерации анкеты Вы получите 500 приветственных баллов.</Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </PageContainer>
    )
  }

  return (
    <Route
      key={key}
      {...rest}
      render={(props) => {
        return <Component path={path} exact={exact} {...props} />
      }}
    />
  )
}

export default PrivateRoute
