import { Box, Grid, Typography } from "@material-ui/core"
// import { useForm } from "react-hook-form"
import { User } from "../../../types/user"
// import { post } from "../../../api/actions"
// import { Msg } from "../../../modal/globalAlert/alert"
import AdvertisingList from "./advertising-list"
import russianRules from "../../../utils/russianRules"
// import Answer from "../../../utils/answer"
import useStyles from "./style"

const Advertising: React.FC<{ user: User; loadInfo: any }> = ({ user }) => {
  const classes = useStyles()
  // const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down("xs"))

  // const [msg, setMsg] = useState<Msg>()
  // const [processed, setProcessed] = useState<boolean>(false)
  // const hookForm = useForm<any>({})
  const { breeder_anketa_social } = user || {}

  // const onSubmit = (props: any) => {
  //   setProcessed(true)
  //
  //   post("/user/breeder/anketa_social", { ...props })
  //     .then((res) => {
  //       setMsg({
  //         message: Answer(res.message),
  //         onClose: () => {
  //           setMsg(undefined)
  //           loadInfo()
  //         }
  //       })
  //     })
  //     .catch(() => {})
  //     .finally(() => {
  //       setProcessed(false)
  //     })
  // }

  return (
    <Grid container justify="flex-start" className="animated fadeIn" spacing={4}>
      {/* <GlobalAlertModal msg={msg} /> */}

      <Grid item xs={12} sm={6}>
        <Typography variant="h4" color="secondary">
          РЕКЛАМА в СОЦ. СЕТЯХ
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box py={5} className={`${classes.root} ${classes.mFullWidth}`}>
          <Box pb={2} px={2} maxWidth="1048" style={{ margin: "0px auto" }}>
            <Typography variant="h5" style={{ fontSize: 20 }}>
              {/* {russianRules( */}
              {/*  "Если Вы разместили наш рекламный баннер на своём сайте, у Вас есть видеоролик с использованием корма AlphaPet, или Вы разместили пост в соцсети — добавьте&nbsp;ссылку" */}
              {/* )} */}
              {russianRules(
                `Если Вы хотите разместить наш рекламный баннер на своем  
                  сайте или у Вас есть видеоролик с использованием корма 
                  AlphaPet - напишите на почту 
                  <a class=${classes.link} href='mailto:breeder@alphapet-info.ru'>
                  breeder@alphapet-info.ru</a>`
              )}
            </Typography>
          </Box>

          {/* <Box pt={2} maxWidth={{ xs: "initial", sm: 500 }} px={2} margin="0 auto"> */}
          {/*  <form onSubmit={hookForm.handleSubmit(onSubmit)} noValidate> */}
          {/*    <TextInput */}
          {/*      inputProps={{ fullWidth: sm }} */}
          {/*      name="href" */}
          {/*      label="" */}
          {/*      form={hookForm} */}
          {/*      placeholder="Пример: https://alfapet.ru" */}
          {/*    /> */}

          {/*    <SubmitButton fullWidth={sm} processed={processed}> */}
          {/*      ДОБАВИТЬ ССЫЛКУ */}
          {/*    </SubmitButton> */}
          {/*  </form> */}
          {/* </Box> */}
        </Box>
      </Grid>
      {breeder_anketa_social.length > 0 && (
        <Grid item xs={12}>
          <Box mb={4} className={classes.mFullWidth}>
            <AdvertisingList items={breeder_anketa_social} />
          </Box>
        </Grid>
      )}
    </Grid>
  )
}

export default Advertising
