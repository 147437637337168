import { UseFormReturn } from "react-hook-form"
import { emailInput } from "./autocomlete-input"
import GenericTextInput from "./genericTextInput"

type Props = {
  form: any
  name: string
  placeholder?: string
  disabled?: boolean
}

const EmailInput: React.FC<Props> = ({ name, placeholder, form, disabled = false }) => {
  const { register, formState }: UseFormReturn = form
  return (
    <GenericTextInput
      register={register(name, { required: true, pattern: /^\s*([\w.%+-]+)@([\w-]+\.)+([\w]{2,})\s*$/i })}
      InputProps={{
        inputProps: { ...(emailInput as any) }
      }}
      placeholder={placeholder}
      label="E-mail"
      helperText={formState.errors[name]?.message}
      disabled={disabled}
    />
  )
}

export default EmailInput
