import { UseFormReturn } from "react-hook-form"
import { makeStyles } from "@material-ui/core"
import GenericTextInput from "./genericTextInput"

type Props = {
  form: any
  name: string
  label?: string
  disabled?: boolean
  placeholder?: string
}

const useStyles = makeStyles(() => ({
  root: {
    "& input": {
      height: "auto"
    }
  }
}))

const FileInput: React.FC<Props> = ({ form, name, label = "Файл", placeholder, disabled = false }) => {
  const classes = useStyles()
  const {
    register,
    formState: { errors }
  }: UseFormReturn = form

  return (
    <GenericTextInput
      register={register(name, { required: true })}
      type="file"
      label={label}
      className={classes.root}
      InputProps={{ inputProps: { accept: "image/png,image/jpeg" } }}
      helperText={errors[name]?.message}
      disabled={disabled}
      placeholder={placeholder}
    />
  )
}

export default FileInput
