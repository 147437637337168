import { useForm } from "react-hook-form"
import { Box, Grid } from "@material-ui/core"
import { useEffect, useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import moment from "moment"
import SnackbarAlert from "../../../../components/form-control/snackbarAlert"
import { get, getFias, post } from "../../../../api/actions"
import SubmitButton from "../../../../components/submit-button"
import DateInput from "../../../../components/form-control/dateInput"
import { ApiAnswerStatus } from "../../../../api/types"
import { Entity } from "../../../../types/user"
import Answer from "../../../../utils/answer"
import { Option } from "../../../../components/form-control/autocomplete-dadata-input"
import validationShema from "./validation"
import { AutocompleteSelect } from "../../../../components/form-control/autocomplete-select"
import { AutocompleteDadataInputNew } from "../../../../components/form-control/autocomplete-dadata-input-new"
import DropzoneInput from "../../../../components/form-control/dropzoneInput"

const Form = ({ onClose = () => {}, setMsg = () => {} }: { onClose: any; setMsg: any }) => {
  const [processed, setProcessed] = useState<boolean>(false)

  const hookForm = useForm<any>({ resolver: yupResolver(validationShema) })
  const [species, setSpecies] = useState<Entity[]>()

  const fetch = async (val?: string): Promise<Option[]> => {
    const data = await getFias({ query: val ?? "" })

    return data?.suggestions
      .filter((s: any) => {
        return s.data.fias_level !== "7" && s.data.fias_level !== "65" && !s.data.street && !s.data.house
      })
      .map((s: any) => ({
        value: s?.value,
        title: s?.value,
        key: s?.key,
        unrestricted_value: s?.unrestricted_value,
        city: s?.data.city_with_type,
        region: s?.data.region_with_type,
        area: s?.data.area_with_type,
        np: s?.data.settlement_with_type
      }))
  }

  useEffect(() => {
    get("/data/breed_species/get", {}).then((res) => {
      if (res.status === ApiAnswerStatus.SUCCESS) setSpecies(res.data)
    })
  }, [])

  const onSubmit = (props: any) => {
    setProcessed(true)
    const { address } = props
    const formData = new FormData()

    // eslint-disable-next-line array-callback-return
    Object.keys(props).map((k: string) => {
      if (k === "image_file") {
        for (let i = 0; i < props[k].length; i++) {
          formData.append("image[]", props[k][i])
        }
      } else if (k === "date_at") {
        formData.append("date_at", moment(props[k]).format("yyyy-MM-DD"))
      } else {
        formData.append(k, typeof props[k] === "object" ? props[k].id || props[k] : props[k])
      }
    })

    formData.append("city", address?.city)
    formData.append("region", address?.region)
    formData.append("area", address?.area)
    formData.append("np", address?.np)

    post("/user/breeder/anketa_exhibition", formData)
      .then((res) => {
        setMsg({
          status: res.status,
          message: Answer(res.message),
          onClose: () => {
            setMsg(undefined)
            if (res.status === 1) onClose()
          }
        })
      })
      .catch(() => {})
      .finally(() => {
        setProcessed(false)
      })
  }

  if (!species) {
    return <></>
  }

  return (
    <>
      <form onSubmit={hookForm.handleSubmit(onSubmit)} noValidate>
        <Box maxWidth={480} pt={5} style={{ margin: "0 auto" }}>
          <Box>
            <AutocompleteSelect
              placeholder="Не выбрано"
              label="Питомец"
              form={hookForm}
              name="breed_species_id"
              defaultOptions={species}
            />
          </Box>
          <Box>
            <DateInput label="Дата выставки" name="date_at" form={hookForm} />
          </Box>
          <Box style={{ margin: "0 auto" }}>
            <AutocompleteDadataInputNew
              label="Населенный пункт"
              name="address"
              form={hookForm}
              fetch={fetch}
              placeholder="Например: Москва"
            />
          </Box>

          <Box>
            <DropzoneInput label="Фото выставки/диплома" form={hookForm} name="image_file" />
          </Box>
          <Box>
            <SnackbarAlert form={hookForm} />
          </Box>
        </Box>

        <Box>
          <Grid container justify="center">
            <Grid item xs={6} sm={5}>
              <SubmitButton
                color="secondary"
                variant="contained"
                fullWidth
                disabled={processed}
                processed={processed}
                title="Добавить"
              />
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  )
}

export default Form
