import { useEffect, useState } from "react"
import {
  Box,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Grid,
  Hidden,
  Button,
  Theme,
  makeStyles
} from "@material-ui/core"
import Pagination from "@material-ui/lab/Pagination"
import { useDispatch } from "react-redux"
import { Buyer } from "../../../types/user"
import russianRules from "../../../utils/russianRules"
import Phone from "../../../utils/phone"
import BallView from "../../../components/ball/ball"
import { OpenViewer } from "../../../modal/viewer/viewer"

const countArray = (ar: any) => (ar ? ar.length : 0)

const useStyles = makeStyles(({ breakpoints: { down }, spacing }: Theme) => ({
  root: {
    marginBottom: spacing(5),
    [down("xs")]: { width: "calc(100% + 32px)", marginLeft: -16 }
  }
}))

const BuyersList: React.FC<{ items?: Buyer[] }> = ({ items = [] }) => {
  const [page, setPage] = useState<number>(1)
  const perPage = 5
  const dispatch = useDispatch()
  const classes = useStyles()

  const handleChange = (_event: any, value: number) => {
    setPage(value)
  }

  const [i, setI] = useState<Buyer[]>([])

  useEffect(() => {
    if (items) setI([...items].reverse())
  }, [items])

  return (
    <Box className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            <Hidden mdDown>
              <TableCell>Владелец</TableCell>
              <TableCell>Телефон</TableCell>
              <TableCell>Кличка питомца</TableCell>
              <TableCell>Фото анкеты</TableCell>
              <TableCell>Статус анкеты</TableCell>
              <TableCell>Баллы</TableCell>
            </Hidden>
          </TableRow>
        </TableHead>

        <TableBody>
          {i.length > 0 ? (
            i.slice(perPage * (page - 1), page * perPage).map((owner) => {
              const {
                id,
                first_name,
                last_name,
                middle_name,
                phone,
                pet_name,
                breeder_anketa_owner_status: status,
                breeder_balance,
                primary_src
              } = owner

              return (
                <TableRow key={`owner-${id}`}>
                  <Hidden mdDown>
                    {/* Владелец */}
                    <TableCell>
                      {last_name} {first_name} {middle_name}
                    </TableCell>

                    {/* Телефон */}
                    <TableCell>
                      <Phone>{phone}</Phone>
                    </TableCell>

                    {/* Кличка питомца */}
                    <TableCell>{pet_name}</TableCell>

                    {/* Фото анкеты */}
                    <TableCell>
                      <Button variant="text" color="primary" onClick={() => OpenViewer(primary_src, dispatch)}>
                        <img src="/imgs/foto.svg" alt="" />
                      </Button>
                    </TableCell>

                    {/* Статус анкеты */}
                    <TableCell>{status.title}</TableCell>

                    {/* Баллы */}
                    <TableCell>
                      <BallView>{breeder_balance?.score}</BallView>
                    </TableCell>
                  </Hidden>

                  <Hidden lgUp>
                    <TableCell>
                      <Box textAlign="left" fontSize={14}>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <Grid container direction="column" spacing={1}>
                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid item className="m_cell_title">
                                    Владелец
                                  </Grid>
                                  <Grid item xs={12} className="m_cell">
                                    {last_name} {first_name} {middle_name}
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid item className="m_cell_title">
                                    Телефон
                                  </Grid>
                                  <Grid item xs={12} className="m_cell">
                                    <Phone>{phone}</Phone>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid item className="m_cell_title">
                                    Кличка питомца
                                  </Grid>
                                  <Grid item xs={12} className="m_cell">
                                    {pet_name}
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid item className="m_cell_title">
                                    Фото анкеты
                                  </Grid>
                                  <Grid item xs={12} className="m_cell">
                                    <Button
                                      variant="text"
                                      color="primary"
                                      onClick={() => OpenViewer(primary_src, dispatch)}
                                    >
                                      <img src="/imgs/foto.svg" alt="" />
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={6}>
                            <Grid container direction="column" spacing={1}>
                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid item className="m_cell_title">
                                    Статус анкеты
                                  </Grid>
                                  <Grid item xs={12} className="m_cell">
                                    {status.title}
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid item className="m_cell_title">
                                    Баллы
                                  </Grid>
                                  <Grid item xs={12} className="m_cell">
                                    <BallView>{breeder_balance?.score}</BallView>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </TableCell>
                  </Hidden>
                </TableRow>
              )
            })
          ) : (
            <TableRow>
              <TableCell colSpan={6}>
                {russianRules("Здесь будет информация о зарегистрированных владельцах")}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {countArray(items) > perPage && (
        <Box pt={4} textAlign="center">
          <Pagination
            page={page}
            variant="text"
            onChange={handleChange}
            count={Math.ceil(countArray(items) / perPage)}
          />
        </Box>
      )}
    </Box>
  )
}

export default BuyersList
