import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  slick: {
    position: "relative",
    "& .slick-next": {
      top: -50,
      right: 0,
      color: "#fff",
      zIndex: 2,
      "&:before": {
        content: '""',
        width: 40,
        position: "absolute",
        display: "block",
        height: 40,
        background: 'url("/imgs/s-n.png") no-repeat center #AD7D66',
        border: "2px solid #AD7D66",
        [theme.breakpoints.down("sm")]: {
          width: 25,
          height: 25,
          borderWidth: 1,
          backgroundSize: "40%"
        }
      },
      [theme.breakpoints.down("sm")]: {
        top: -60
      }
    },
    "& .slick-prev": {
      top: -50,
      left: "auto",
      right: 50,
      zIndex: 2,
      "&:before": {
        content: '""',
        width: 40,
        position: "absolute",
        display: "block",
        height: 40,
        background: 'url("/imgs/s-p.png") no-repeat center #fff',
        border: "2px solid #C9B1A6",

        [theme.breakpoints.down("sm")]: {
          width: 25,
          height: 25,
          backgroundSize: "40%",
          borderWidth: 1
        }
      },
      [theme.breakpoints.down("sm")]: {
        top: -60,
        right: 35
      }
    },
    "& .slick-slide img": {
      margin: "0px auto"
    }
  },
  imgs: {
    // height: 315,
    "& img": {
      maxWidth: "100%"
    }
  },
  prizItem: {
    height: "100%",
    color: "#3E3E3E",
    textAlign: "center",
    background: "#fff",

    transitionDuration: "0.3s",
    "& .name": {
      minHeight: 55,
      fontSize: 15,
      height: 55,
      overflow: "hidden",
      textAlign: "center",
      fontWeight: 500,
      "& b": {
        fontWeight: 700
      }
    },
    "& .ball": {
      fontWeight: 700,
      fontSize: 23,
      "& img": {}
    },
    "&:hover": {
      background: "#F0EDEC"
    }
  }
}))

export default useStyles
