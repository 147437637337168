const Answer: React.FC<any> = (message: any) => {
  if (message === null) {
    return { alert: ["Ошибка сервера. Пожалуйста, попробуйте позже."] }
  }

  if (typeof message === "string") {
    return { alert: [message] }
  }

  return message
}

export default Answer
