import { Action } from "redux"
import { PROPS, SET_PROPS, ProjectProps } from "./types"

const initialState: ProjectProps = {
  isAuth: false,
  userStatus: null,
  init: false,
  modal: {}
}

export interface DispatchAction extends Action {
  payload: Partial<ProjectProps>
}
export default (state = initialState, action: PROPS) => {
  switch (action.type) {
    case SET_PROPS:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
