import { Box, Grid, Hidden, makeStyles } from "@material-ui/core"
import Menu from "../menu"
import MobileMenu from "./mobile-menu"

const useStyles = makeStyles((theme) => ({
  headerBody: {
    backgroundColor: "transparent",
    height: "100%",
    textTransform: "uppercase",
    userSelect: "none"
  },
  header: {
    position: "absolute",
    height: 80,
    width: "100%",
    zIndex: 100,

    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#fff",
      height: 56,
      position: "fixed"
    }
  }
}))

const Header = () => {
  const classes = useStyles()

  return (
    <>
      <Box className={[classes.header].join(" ")} id="main">
        <Grid container justify="center" alignItems="center" className={classes.headerBody}>
          <Hidden smDown>
            <Menu />
          </Hidden>
          <Hidden mdUp>
            <Grid container alignItems="center" alignContent="center" justify="space-around">
              <MobileMenu menu={<Menu />} />
            </Grid>
          </Hidden>
        </Grid>
      </Box>
    </>
  )
}

export default Header
