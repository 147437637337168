import Box from "@material-ui/core/Box"

type Prop = {
  children: React.ReactNode
  index: number
  value: number
  className?: any
}

const TabPanel: React.FC<Prop> = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

export default TabPanel
