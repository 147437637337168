import { Box, Grid } from "@material-ui/core"
import NumberFormat from "react-number-format"

const BallView = (props: any) => {
  const { children, justify = "center" } = props
  return (
    <Grid container className="ball" wrap="nowrap" {...{ justify, ...props }}>
      <Grid item>
        {children === undefined || children === " " ? (
          <Box color="text.disabled" fontSize={{ xs: 18, sm: 22 }}>
            &mdash;&nbsp;
          </Box>
        ) : (
          <NumberFormat displayType="text" thousandSeparator="&nbsp;" value={children} suffix="&nbsp;" />
        )}
      </Grid>
      {!(children === undefined || children === " ") && (
        <Grid item>
          <img src="/imgs/icon-ball.svg" alt="" />
        </Grid>
      )}
    </Grid>
  )
}
export default BallView
