import { Box, IconButton, makeStyles, Theme } from "@material-ui/core"
import Close from "@material-ui/icons/Close"
import React from "react"

type Props = {
  close?: any
  disabled?: boolean
}

const useStyles = makeStyles(({ breakpoints: { down }, spacing }: Theme) => ({
  buttonDialogCloseContainer: {
    textAlign: "right",
    position: "absolute",
    right: 0,
    top: -10,
    overflow: "hidden",
    width: spacing(10),
    height: spacing(10),
    [down("xs")]: { top: -1 }
  },

  buttonDialogClose: {
    color: "#000000",
    background: "#F5ECE5",
    fontWeight: 200,
    borderRadius: 0,
    padding: 5,
    "& span": { transitionDuration: "0.5s" },
    "&:hover span": { transform: "rotate(90deg)" }
  },

  icon: {
    fontSize: 25,
    [down("xs")]: { fontSize: 25 }
  }
}))

const CloseButton: React.FC<Props> = ({ close = () => {}, disabled = false }) => {
  const classes = useStyles()

  return (
    <Box flex="0 1 auto" className={classes.buttonDialogCloseContainer}>
      <IconButton
        onClick={() => {
          close()
        }}
        disabled={disabled}
        aria-label="close"
        className={classes.buttonDialogClose}
      >
        <Close className={classes.icon} />
      </IconButton>
    </Box>
  )
}
export default CloseButton
