import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import Dialog from "../../components/dialog"
import { PrizeType } from "../../types/prize"
import Ball from "../../components/ball/ball"
import russianRules from "../../utils/russianRules"

export type Msg = {
  title?: string
  message?: any
  onClose?: any
  prize: PrizeType
  scope: number
}

export const PrizeInfoModal: React.FC<{ msgs: Msg; handleOrder: any }> = ({ msgs, handleOrder = () => {} }) => {
  const { prize, scope, onClose = () => {} } = msgs

  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <>
      <Dialog maxWidth="md" onOpen={() => {}} onCloseDialog={onClose} name="alert-modal" open={!!prize}>
        <Box textAlign="left">
          <Grid container justify="center">
            <Grid item xs={12} sm={5} style={{ textAlign: "center" }}>
              <Box pb={2}>
                <img
                  style={{ width: "100%", maxWidth: 330 }}
                  src={`/imgs/prizs/${prize?.key}.png`}
                  alt={prize?.title}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box height="90%">
                <Grid container style={{ height: "100%" }} direction="column" wrap="nowrap" justify="space-between">
                  <Grid item xs={12}>
                    <Box textAlign="left">
                      <Typography
                        variant="h3"
                        color="secondary"
                        style={{ textAlign: md ? "left" : "center", fontSize: 25, fontWeight: 700 }}
                      >
                        {russianRules(prize?.description)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} style={{ flex: "1 1 auto" }}>
                    <Box py={3}>
                      <Ball
                        style={{ fontSize: 40, fontWeight: 700 }}
                        justify={md ? "flex-start" : "center"}
                        textAlign="left"
                      >
                        {prize?.score}
                      </Ball>
                    </Box>
                    <Box textAlign={md ? "left" : "center"}>
                      <Button
                        disabled={scope < prize.score}
                        variant="outlined"
                        color="primary"
                        onClick={() => handleOrder(prize)}
                      >
                        Заказать
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  )
}
