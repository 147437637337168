import { Box, Grid, Typography, makeStyles, Button, useMediaQuery, Theme } from "@material-ui/core"
import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import Moment from "react-moment"
import { useHistory, Link } from "react-router-dom"
import { AnketaOffspring, User } from "../../../types/user"
import BuyersList from "./owner-list"
import { SET_PROPS } from "../../../store/props/types"
import { modalName as BuyerAddModalName } from "../modal/buyer"
import CustomLink from "../../../components/link"
import { useSelector } from "../../../hooks"

const useStyles = makeStyles(({ breakpoints: { down } }: Theme) => ({
  tabPanel: {
    "& > div": {
      padding: 0
    }
  },
  link: {
    textDecoration: "none",
    borderBottom: "1px dashed #8C9297",
    color: "#8C9297",
    fontSize: 15
  },
  root: {
    padding: "40px 15px",
    background: "#F0EDEC",
    textAlign: "center",
    [down("xs")]: { width: "calc(100% + 32px)", marginLeft: -16, padding: "40px 0" }
  }
}))

const Owner: React.FC<{ user: User }> = ({ user }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { breeder_anketa_offspring } = user || {}
  const { doc } = useSelector((state) => state.propsReducer)
  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down("xs"))
  const { location } = useHistory()
  const { search } = location

  const [offspring, setOffspring] = useState<AnketaOffspring>()

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    breeder_anketa_offspring.map((i: AnketaOffspring) => {
      if (String(i.id) === search.split("=")[1]) {
        setOffspring(i)
      }
    })
  }, [user])

  return (
    <Grid container justify="flex-start" className="animated fadeIn" spacing={4}>
      <Grid item xs={12}>
        <Box fontWeight={600} fontSize={14}>
          <Link
            className="back"
            style={{ textTransform: "uppercase", textDecoration: "none" }}
            color="secondary"
            to="/profile/#offspring"
          >
            <img src="/imgs/arrow-rev.svg" alt="" />
            &nbsp;&nbsp;Назад
          </Link>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Grid container alignItems="center" justify="space-between" alignContent="center" spacing={2}>
          <Grid item xs={12} md="auto">
            <Typography variant="h4" color="secondary" style={{ fontWeight: 700 }}>
              Дата помёта: <Moment format="DD.MM.yyyy">{offspring?.birthday_at}</Moment>
            </Typography>
            <Box pt={{ xs: 0, md: 1 }} fontWeight={600} fontSize={{ xs: 14, sm: 18 }}>
              {offspring?.breed?.title}
            </Box>
          </Grid>
          <Grid item xs="auto">
            {doc?.owner_profile && (
              <Grid container alignItems="center" wrap="nowrap" justify="space-between" spacing={1}>
                <Grid item>
                  <img src="/imgs/pdf.svg" style={{ display: "block" }} alt="" />
                </Grid>
                <Grid item>
                  <CustomLink className={classes.link} href={doc.owner_profile.primary_src} target="_blank">
                    Скачать анкету владельца
                  </CustomLink>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box py={5} className={classes.root}>
          <Box pb={{ xs: 2, sm: 4 }}>
            <Typography variant="h5">
              Зарегистрируйте анкету нового владельца
              <br /> и получите 50 баллов.
            </Typography>
          </Box>

          {offspring?.anketa_owner_aviable && (
            <Box px={2}>
              <Button
                fullWidth={sm}
                disabled={!offspring?.anketa_owner_aviable}
                variant="contained"
                color="primary"
                onClick={() => {
                  dispatch({
                    type: SET_PROPS,
                    payload: { modal: { [BuyerAddModalName]: { show: true } } }
                  })
                }}
              >
                Добавить Владельца
              </Button>
            </Box>
          )}
        </Box>
      </Grid>

      <Grid item xs={12}>
        <BuyersList items={offspring?.breeder_anketa_owner} />
      </Grid>
    </Grid>
  )
}

export default Owner
