import { UseFormReturn } from "react-hook-form"
import PhoneMask from "./mask/phone-mask"
import { numericInput } from "./autocomlete-input"
import GenericTextInput from "./genericTextInput"

type Props = {
  form: UseFormReturn<any>
  name: string
  disabled?: boolean
  placeholder?: string
  label?: string
  required?: boolean
}

const PhoneInput: React.FC<Props> = ({ form, name, required = true, label = "Телефон", disabled = false }) => {
  const { register, formState } = form

  return (
    <GenericTextInput
      register={register(name, {
        required,
        disabled,
        pattern: /(^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$) || (^\d{10}$)/i,
        setValueAs: (value) => (typeof value === "string" ? value?.replace(/\D/g, "").replace(/^7/g, "") : value)
      })}
      placeholder="+7 (999) 000 00 00"
      disabled={disabled}
      label={label}
      InputProps={{
        inputComponent: PhoneMask as any,
        inputProps: { ...(numericInput as any) }
      }}
      helperText={formState.errors[name]?.message}
    />
  )
}

export default PhoneInput
