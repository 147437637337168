import { useForm } from "react-hook-form"
import { Box, Grid } from "@material-ui/core"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import SnackbarAlert from "../../../../components/form-control/snackbarAlert"
import NameInput from "../../../../components/form-control/nameInput"
import EmailInput from "../../../../components/form-control/emialInput"
import PhoneInput from "../../../../components/form-control/phoneInput"
import { getFias, post } from "../../../../api/actions"
import SubmitButton from "../../../../components/submit-button"
import FileInput from "../../../../components/form-control/fileInput"
import SecondNameInput from "../../../../components/form-control/secondNameInput"
import Answer from "../../../../utils/answer"
import validationShema from "./validation"
import { AutocompleteDadataInputNew, Option } from "../../../../components/form-control/autocomplete-dadata-input-new"

const Form: React.FC<{ setMsg: any; onClose: any }> = ({ onClose, setMsg }) => {
  const [processed, setProcessed] = useState<boolean>(false)
  const hookForm = useForm<any>({ resolver: yupResolver(validationShema) })
  const { location } = useHistory()
  const { search } = location

  const fetch = async (val?: string): Promise<Option[]> => {
    const data = await getFias({ query: val ?? "" })
    return data?.suggestions
      .filter((s: any) => {
        return s.data.fias_level !== "7" && s.data.fias_level !== "65" && !s.data.street && !s.data.house
      })
      .map((s: any) => ({
        value: s?.value,
        title: s?.value,
        unrestricted_value: s?.unrestricted_value,
        city: s?.data.city_with_type,
        region: s?.data.region_with_type,
        area: s?.data.area_with_type,
        np: s?.data.settlement_with_type
      }))
  }

  const onError = (props: any) => {
    console.log(props, hookForm.getValues())
  }

  const onSubmit = (props: any) => {
    setProcessed(true)
    const { phone = "", address } = props
    const formData = new FormData()
    const id = search.split("=")[1]

    // eslint-disable-next-line array-callback-return
    Object.keys(props).map((k: string) => {
      if (k === "image_file") {
        for (let i = 0; i < props[k].length; i++) {
          formData.append("image", props[k][i])
        }
      } else {
        formData.append(k, typeof props[k] === "object" ? props[k].id || props[k] : props[k])
      }
    })

    formData.append("phone", phone.replace(/\D/g, "").substr(-10))
    formData.append("city", address?.city)
    formData.append("region", address?.region)
    formData.append("area", address?.area)
    formData.append("np", address?.np)

    post(`/user/breeder/anketa_offspring/${id}/create_anketa_owner`, formData)
      .then((res) => {
        setMsg({
          status: res.status,
          message: Answer(res.message),
          onClose: () => {
            setMsg(undefined)
            if (res.status === 1) onClose()
          }
        })
      })
      .catch(() => {})
      .finally(() => {
        setProcessed(false)
      })
  }

  return (
    <>
      <form onSubmit={hookForm.handleSubmit(onSubmit, onError)} noValidate>
        <Box maxWidth={480} pt={5} style={{ margin: "0 auto" }}>
          <Box>
            <NameInput name="last_name" label="Фамилия" form={hookForm} placeholder="Пример: Иванов" />
          </Box>
          <Box>
            <NameInput name="first_name" label="Имя" form={hookForm} placeholder="Пример: Иван" />
          </Box>
          <Box>
            <SecondNameInput name="middle_name" label="Отчество" form={hookForm} placeholder="Пример: Иванович" />
          </Box>

          <Box style={{ margin: "0 auto" }}>
            <AutocompleteDadataInputNew
              label="Населенный пункт"
              name="address"
              form={hookForm}
              fetch={fetch}
              placeholder="Например: Москва"
            />
          </Box>

          <Box>
            <PhoneInput name="phone" form={hookForm} placeholder="+7 (999) 000 00 00" />
          </Box>
          <Box>
            <EmailInput name="email" form={hookForm} placeholder="Пример: examole@domain.com" />
          </Box>

          <Box>
            <NameInput name="pet_name" label="Кличка щенка/котенка" form={hookForm} placeholder="Пример: Фантик" />
          </Box>
          <Box>
            <FileInput
              name="image_file"
              label="Фото анкеты владельца"
              form={hookForm}
              placeholder="Фото анкеты владельца"
            />
          </Box>

          <Box>
            <SnackbarAlert form={hookForm} />
          </Box>
        </Box>
        <Box>
          <Grid container justify="center">
            <Grid item xs={6} sm={5}>
              <SubmitButton
                color="secondary"
                variant="contained"
                fullWidth
                disabled={processed}
                processed={processed}
                title="Добавить"
              />
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  )
}

export default Form
