import { makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles(({ breakpoints: { down }, spacing }: Theme) => ({
  tabPanel: {
    "& > div": {
      padding: 0
    }
  },

  // link: {
  //   textDecoration: "none",
  //   borderBottom: "1px dashed #8C9297",
  //   color: "#8C9297",
  //   fontSize: 15
  // },

  root: {
    padding: "40px 15px",
    background: "#F0EDEC",
    textAlign: "center",
    [down("xs")]: { width: "calc(100% + 32px)", marginLeft: -16, padding: "40px 0" }
  },

  list: {
    marginBottom: spacing(5),
    [down("xs")]: { width: "calc(100% + 32px)", marginLeft: -16 }
  },

  box1: {
    padding: spacing(2),
    textAlign: "center",
    color: "#323E48",
    background: "#D2E1E6",
    height: "100%",
    "& .label": {
      fontSize: 14,
      textTransform: "uppercase",
      marginBottom: spacing(1),
      fontWeight: 600,
      [down("xs")]: { fontSize: 12 }
    },
    "& .ball": {
      lineHeight: 1,
      fontSize: 40,
      textTransform: "uppercase",
      fontWeight: 700,
      [down("xs")]: { fontSize: 28 }
    }
  },

  box2: {
    padding: spacing(2),
    textAlign: "center",
    color: "#323E48",
    background: "#E9F0F2",
    height: "100%",
    "& .label": {
      marginBottom: spacing(1),
      fontSize: 14,
      textTransform: "uppercase",
      fontWeight: 600,
      [down("xs")]: { fontSize: 12 },
      "&.label_long": { [down("xs")]: { fontSize: 9 } }
    },
    "& .ball": {
      lineHeight: 1,
      fontSize: 28,
      textTransform: "uppercase",
      fontWeight: 700,
      [down("xs")]: { fontSize: 22 }
    }
  },

  box3: {
    textAlign: "center",
    color: "#323E48",
    background: "#F0EDEC",
    height: "100%",
    padding: "5%",
    paddingTop: 20,

    "& .title": {
      paddingTop: 0,
      paddingBottom: 5,
      fontSize: 30,
      textTransform: "uppercase",
      fontWeight: 700,
      textAlign: "left",
      color: "#AD7D66",
      [down("xs")]: { fontSize: 15 }
    },
    "& .text": {
      fontSize: 14,
      textTransform: "uppercase",
      fontWeight: 600,
      textAlign: "left",
      color: "#566169",
      [down("xs")]: { fontSize: 10 }
    },
    "& .label": {
      paddingTop: 17,
      marginBottom: spacing(1),
      fontSize: 14,
      textTransform: "uppercase",
      fontWeight: 600
    },
    "& .ball": {
      lineHeight: 1,
      fontSize: 28,
      textTransform: "uppercase",
      fontWeight: 700,
      [down("xs")]: { fontSize: 14 }
      // paddingBottom: 5
    },
    "& .hr": {
      width: "100%",
      height: 15,
      marginTop: 50,
      [down("xs")]: { marginTop: 28 }
    },
    "& .hr.p1": {
      backgroundColor: "#A77B66"
    },
    "& .hr.p2": {
      backgroundColor: "#B89280"
    },
    "& .hr.p3": {
      backgroundColor: "#C9B1A6"
    },
    "& .t": {
      paddingTop: 10,
      fontSize: 18,
      color: "#8C9297",
      fontWeight: 600,
      textAlign: "left",
      textDecoration: "underline",
      [down("md")]: { fontSize: 16 },
      [down("sm")]: { fontSize: 18 },
      [down("xs")]: { fontSize: 8 }
    },
    "& .t.b": {
      color: "#000"
    }
  },

  link: {
    borderRight: "1px solid #fff",
    borderLeft: "1px solid #fff",
    borderTop: "1px solid #fff",
    borderBottom: "1px solid #fff",
    "& td": {
      borderTop: "1px solid #fff",
      borderBottom: "1px solid #fff"
    },

    "& .link": {
      color: "transparent!important",
      fontSize: "35px!important",
      lineHeight: 1,
      cursor: "pointer",
      transitionDuration: "0.2s"
    },
    "&:hover .link": {
      color: "#fff!important",
      backgroundColor: "#AD7D66!important"
    },
    "&:hover": {
      borderRight: "1px solid #AD7D66",
      borderLeft: "1px solid #AD7D66"
    },
    "&:hover td": {
      borderTop: "1px solid #AD7D66!important",
      borderBottom: "1px solid #AD7D66!important"
    }
  },

  btn: {
    padding: "5px 10px"
  }
}))

export default useStyles
