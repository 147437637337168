import { Box } from "@material-ui/core"
import { useForm } from "react-hook-form"
import { useState } from "react"
import Dialog from "../../components/dialog"
import { PrizeType } from "../../types/prize"
import NumberInput from "../../components/form-control/numberInput"
import SubmitButton from "../../components/submit-button"
import { getFiasFull, post } from "../../api/actions"

import Answer from "../../utils/answer"
import { AutocompleteDadataInputNew, Option } from "../../components/form-control/autocomplete-dadata-input-new"

export type Msg = {
  title?: string
  message?: any
  onClose?: any
  prize: PrizeType
  scope: number
}

export const ConfirmModal: React.FC<{ msgs?: Msg; setMsg: any }> = ({ msgs, setMsg }) => {
  const [processed, setProcessed] = useState<boolean>(false)
  const { prize, scope, title = "Подтвердите действие", message, onClose = () => {} } = msgs || {}

  const hookForm = useForm<any>({ defaultValues: { breeder_priz_id: prize?.id } })

  const onSubmit = (props: any) => {
    post(`/user/breeder/priz/create`, {
      ...props,
      address: props.address.unrestricted_value
    })
      .then((res) => {
        setMsg({
          status: res.status || 0,
          message: Answer(res.message),
          onClose: () => {
            setMsg(undefined)
            onClose()
          }
        })
      })
      .catch(() => {})
      .finally(() => {
        setProcessed(false)
      })
  }

  const fetch = async (val?: string): Promise<Option[]> => {
    const data = await getFiasFull({ query: val ?? "" })

    return data?.suggestions.map((s: any) => ({
      value: s?.value,
      title: s?.value,
      key: s?.unrestricted_value,
      unrestricted_value: s?.unrestricted_value
    }))
  }

  return (
    <>
      <Dialog title={title} maxWidth="sm" onOpen={() => {}} onCloseDialog={onClose} name="alert-modal" open={!!prize}>
        {message}
        <form onSubmit={hookForm.handleSubmit(onSubmit)} noValidate>
          <Box maxWidth={480} pt={2} style={{ margin: "0 auto" }}>
            <Box>Вы действительно хотите заказать приз:</Box>
            <Box>
              <b>&quot;{prize?.title}&quot;</b>
            </Box>
            {prize?.need_amount && (
              <Box>
                <Box pt={4} maxWidth={300} style={{ margin: "0 auto" }}>
                  <NumberInput
                    name="amount"
                    label="Введите сумму в баллах. 1 балл = 1 руб"
                    form={hookForm}
                    max={scope}
                    placeholder="Пример: 100"
                  />
                </Box>
              </Box>
            )}
            {prize?.need_address && (
              <Box>
                <Box pt={4} style={{ margin: "0 auto" }}>
                  <Box style={{ margin: "0 auto" }}>
                    <AutocompleteDadataInputNew
                      label="Адрес доставки"
                      name="address"
                      form={hookForm}
                      fetch={fetch}
                      placeholder="Например: Москва"
                    />
                  </Box>
                </Box>
              </Box>
            )}

            <Box pt={5}>
              <SubmitButton processed={processed}>Подтвердить</SubmitButton>
            </Box>
          </Box>
        </form>
      </Dialog>
    </>
  )
}
