import { makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles(({ breakpoints: { down } }: Theme) => ({
  link: {
    fontSize: 22,
    fontWeight: 500,
    color: "#323E48",
    textDecoration: "none",

    borderBottom: "1px dashed #8C9297",
    "&:hover": { textDecoration: "none" },
    [down("md")]: { fontSize: 18 },
    [down("sm")]: { fontSize: 18 }
  },
  submenu: {
    fontSize: 22,
    fontWeight: 700,
    color: "#323E48",
    textTransform: "uppercase",
    paddingBottom: 10,
    paddingTop: 10,
    // "&.active": { color: "#AD7D66" },
    "&.actives": { color: "#AD7D66" },
    [down("md")]: { fontSize: 18 },
    [down("sm")]: { fontSize: 22 },
    [down("xs")]: { fontSize: 20 }
  }
}))

export default useStyles
