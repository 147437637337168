import { UseFormReturn } from "react-hook-form"
import { numericInput } from "./autocomlete-input"
import GenericTextInput from "./genericTextInput"

type Props = {
  form: any
  name: string
  label?: string
  placeholder?: string
  disabled?: boolean
  required?: boolean
  max?: number
}

const NumberInput: React.FC<Props> = ({
  form,
  name,
  max,
  required = true,
  placeholder,
  label = "Код",
  disabled = false
}) => {
  const { register, formState }: UseFormReturn = form
  return (
    <GenericTextInput
      register={register(name, { required, valueAsNumber: true })}
      InputProps={{
        inputProps: { min: 1, max, ...(numericInput as any) }
      }}
      type="number"
      label={label}
      placeholder={placeholder}
      helperText={formState.errors[name]?.message}
      disabled={disabled}
    />
  )
}

export default NumberInput
