import { makeStyles } from "@material-ui/styles"
import palette from "../../theme/palette"

const useStyles = makeStyles(() => ({
  link: {
    fontWeight: 400,
    color: palette.primary.main
  },
  fieldContainer: {
    textAlign: "center"
  },
  socialLabel: {
    fontSize: "72%",
    textTransform: "none"
  }
}))

export default useStyles
