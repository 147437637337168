import { useEffect, useState } from "react"
import { usePrevious } from "react-use"
import { Grid } from "@material-ui/core"
import { useDispatch } from "react-redux"
import useReactRouter from "use-react-router"
import { useHistory } from "react-router-dom"
import { parse } from "query-string"
import ModalRoot from "./modal-root"
import { useSelector } from "../hooks"
import { SET_PROPS } from "../store/props/types"
import { ApiAnswerStatus } from "../api/types"
import { constants } from "../api/actions"
import AuthAction from "../store/auth/action"
import Routes from "./routes"
import Header from "../page/layout/header"
import Footer from "../page/layout/footer"
import { fetchPrivateAPITokenSoc } from "../api/service"
import { Error50xPage } from "../page/error"

const App = () => {
  const dispatch = useDispatch()
  const { location } = useReactRouter()
  const { pathname } = location
  const prevLocation = usePrevious(location)
  const { pathname: prevPathname } = prevLocation || {}
  const removeBodyClass = (className: string) => document.body.classList.remove(className)
  const [processed, setProcessed] = useState(false)
  const { loading } = useSelector((state) => state.authReducer)
  const { load } = useSelector((state) => state.propsReducer)

  const history = useHistory()
  const {
    location: { search }
  } = history

  const { access_token, provider } = parse(search)

  useEffect(() => {
    setProcessed(loading)
  }, [loading])

  const validateAuth = () => {
    return dispatch(
      AuthAction(
        (d) => {
          dispatch({
            type: SET_PROPS,
            payload: { isAuth: true, userStatus: d?.data?.breeder_status?.key }
          })
        },
        () => {
          dispatch({
            type: SET_PROPS,
            payload: { isAuth: false, userStatus: null }
          })
        }
      )
    )
  }

  useEffect(() => {
    if (access_token && provider) {
      fetchPrivateAPITokenSoc(access_token, provider).then((res) => {
        if (res.status === 1) {
          dispatch(
            AuthAction(
              (d) => {
                dispatch({
                  type: SET_PROPS,
                  payload: { isAuth: true, userStatus: d?.data?.breeder_status?.key }
                })
                history.push("/profile/#main")
              },
              () => {
                dispatch({
                  type: SET_PROPS,
                  payload: { isAuth: false, userStatus: null }
                })
              }
            )
          )
        }
      })
    }
  }, [access_token, provider])

  const requestProjectConstant = () => {
    return constants().then((r) => {
      if (r.status === ApiAnswerStatus.SUCCESS) {
        const { CAPTCHA_SITEKEY: recaptchaSitekey, DOC: doc } = r.data || {}
        dispatch({
          type: SET_PROPS,
          payload: {
            doc,
            recaptchaSitekey,
            load: true
          }
        })
      } else {
        dispatch({ type: SET_PROPS, payload: { load: false } })
      }
    })
  }

  useEffect(() => {
    if (!processed && prevPathname !== pathname && pathname !== "/signout") {
      setProcessed(true)
      dispatch({ type: SET_PROPS, payload: { init: false } })
      const p = Promise.all([requestProjectConstant(), validateAuth()])
      p.then((r) => {
        dispatch({ type: SET_PROPS, payload: { init: true } })
        return r
      })
    }
  }, [pathname])

  useEffect(() => {
    if (load !== undefined) {
      setProcessed(false)

      const preloader = document.querySelector("#preloader")
      if (preloader) {
        setTimeout(function () {
          preloader.classList.add("animate__fadeOut")
        }, 1000)
        setTimeout(function () {
          preloader.remove()
          removeBodyClass("loading")
        }, 2000)
      }
    }
  }, [load])

  useEffect(() => {
    const timer = setInterval(() => {
      if (!load) {
        requestProjectConstant()
      } else {
        clearInterval(timer)
      }
    }, 10000)
    return () => clearInterval(timer)
  }, [load])

  if (load === false) {
    return <Error50xPage />
  }

  return (
    <>
      <ModalRoot loadInfo={validateAuth} />
      <Header />
      <Grid container style={{ height: "100%" }} wrap="nowrap" direction="column" justify="space-between" spacing={0}>
        <Grid item xs={12} style={{ flex: "1 1 auto" }}>
          <Routes />
        </Grid>
        <Grid item xs={12} style={{ flex: "0 1 auto" }}>
          <Footer />
        </Grid>
      </Grid>
    </>
  )
}

export default App
