import { Box } from "@material-ui/core"
import { useForm, useWatch } from "react-hook-form"
import { useEffect, useState } from "react"
import CheckboxInput from "../form-control/checkboxInput"
import { post } from "../../api/actions"
import { User } from "../../types/user"
import { GlobalAlertModal } from "../../modal/globalAlert"
import { Msg } from "../../modal/globalAlert/alert"
import { ApiAnswerStatus } from "../../api/types"

const UpdateSubscribe: React.FC<{ user: User; loadInfo: any }> = ({ user, loadInfo }) => {
  const hookForm = useForm<any>({
    defaultValues: { subscribe_email: user.subscribe_email, subscribe_sms: user.subscribe_sms }
  })

  const [msg, setMsg] = useState<Msg>()
  const { control, getValues } = hookForm

  const subscribe_sms = useWatch({ control, name: "subscribe_sms" })
  const subscribe_email = useWatch({ control, name: "subscribe_email" })

  useEffect(() => {
    if (
      subscribe_email !== undefined &&
      subscribe_sms !== undefined &&
      (user.subscribe_email !== subscribe_email || user.subscribe_sms !== subscribe_sms)
    ) {
      post("/user/breeder/update", { ...getValues() })
        .then((res) => {
          let m

          if (user.subscribe_email !== subscribe_email) {
            m = subscribe_email
              ? "Обращаем внимание, что Вы подписались на получения информационных материалов на e-mail"
              : "Обращаем внимание, что Вы отписались от получения информационных материалов на e-mail"
          }

          if (user.subscribe_sms !== subscribe_sms) {
            m = subscribe_sms
              ? "Обращаем внимание, что Вы подписались на получения информационных материалов в смс"
              : "Обращаем внимание, что Вы отписались от получения информационных материалов в смс"
          }

          setMsg({
            ...res,
            message: res.status === ApiAnswerStatus.SUCCESS ? { alert: [m] } : res.message,
            onClose: () => {
              setMsg(undefined)
              loadInfo()
            }
          })
        })
        .catch(() => {})
        .finally(() => {})
    }
  }, [subscribe_sms, subscribe_email])

  if (!user) {
    return <></>
  }

  return (
    <Box py={2}>
      <GlobalAlertModal msg={msg} />
      <form>
        <Box textAlign="left">
          <CheckboxInput label={<>Я согласен на смс рассылку</>} name="subscribe_sms" form={hookForm} />
        </Box>

        <Box textAlign="left">
          <CheckboxInput label={<>Я согласен на e-mail рассылку</>} name="subscribe_email" form={hookForm} />
        </Box>
      </form>
    </Box>
  )
}

export default UpdateSubscribe
