import { Box, Button, Grid } from "@material-ui/core"
import Dialog from "../../components/dialog"

export type Msg = {
  title?: string
  message?: any
  onOk?: any
  onClose?: any
}

export const GlobalConfirmAlert: React.FC<{ msgs?: Msg }> = ({ msgs }) => {
  const { title = "Подтвердите действие", message, onClose = () => {}, onOk = () => {} } = msgs || {}

  return (
    <>
      <Dialog
        title={title}
        maxWidth="sm"
        onOpen={() => {}}
        onCloseDialog={onClose}
        name="alert-modal"
        open={message !== undefined}
      >
        {message}
        <Box pt={5}>
          <Grid container justify="center" spacing={2}>
            <Grid item>
              <Button variant="contained" color="secondary" onClick={onClose}>
                Нет
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="secondary" onClick={onOk}>
                Да
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  )
}
