import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import Dialog from "../../components/dialog"
import TrackEvent from "../../components/GAnalitics"
import { useSelector } from "../../hooks"
import ChangePasswordForm from "./form"
import { SET_PROPS } from "../../store/props/types"
import { GlobalAlertModal } from "../globalAlert"
import { Msg } from "../globalAlert/alert"

export const modalName = "ChangePasswordModalName"

const ChangePasswordModal: React.FC<{ onClose: any }> = ({ onClose }) => {
  const dispatch = useDispatch()
  const { modal } = useSelector((state) => state.propsReducer)
  const [msg, setMsg] = useState<Msg>()
  const { show = false } = modal[modalName] || {}
  const onCloses = () => {
    onClose()
    dispatch({
      type: SET_PROPS,
      payload: {
        modal: {
          [modalName]: { show: false }
        }
      }
    })
  }

  useEffect(() => {
    if (msg && msg.status === 1) {
      onCloses()
    }
  }, [msg])

  return (
    <>
      <GlobalAlertModal msg={msg} />
      <Dialog
        title="Смена пароля"
        maxWidth="sm"
        onOpen={() => {
          TrackEvent({ eventCategory: "ChangePassword", eventAction: "open_f_form" })
        }}
        onCloseDialog={onCloses}
        name={modalName}
        open={show}
      >
        <ChangePasswordForm setMsg={setMsg} onClose={onCloses} />
      </Dialog>
    </>
  )
}

export default ChangePasswordModal
