import { useForm } from "react-hook-form"
import { Box, Button, Grid } from "@material-ui/core"
import { useEffect, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { useHistory } from "react-router-dom"
import { parse } from "query-string"
import { yupResolver } from "@hookform/resolvers/yup"
import moment from "moment"
import { getFias, getPublick, post, signup } from "../../api/actions"
import TrackEvent from "../../components/GAnalitics"
import { useSelector } from "../../hooks"
import { Entity } from "../../types/user"
import NameInput from "../../components/form-control/nameInput"
import PhoneInput from "../../components/form-control/phoneInput"
import EmailInput from "../../components/form-control/emialInput"
import DateInput from "../../components/form-control/dateInput"
import { ApiAnswerStatus } from "../../api/types"
import SocialAuth from "../../components/social-auth"
import SubmitButton from "../../components/submit-button"
import CheckboxInput from "../../components/form-control/checkboxInput"
import SecondNameInput from "../../components/form-control/secondNameInput"
import Answer from "../../utils/answer"
import RefInput from "../../components/form-control/refInput"
import { AutocompleteDadataInputNew, Option } from "../../components/form-control/autocomplete-dadata-input-new"
import useStyles from "./style"
import validationShema from "./validation"
import DropzoneInput from "../../components/form-control/dropzoneInput"
import SnackbarAlert from "../../components/form-control/snackbarAlert"
import { AutocompleteSelect } from "../../components/form-control/autocomplete-select"

const SignupForm: React.FC<{ setMsg: any }> = ({ setMsg }) => {
  const classes = useStyles()
  const { isAuth, doc } = useSelector((state) => state.propsReducer)
  const history = useHistory()
  const [recaptchaRef, setRecaptchaRef] = useState<any>()
  const [step, setStep] = useState<number>(1)
  const [nursery, setNursery] = useState<Entity[]>([])
  const [formData, setFormData] = useState<any>(null)
  const [processed, setProcessed] = useState<boolean>(false)
  const {
    location: { search }
  } = history

  const { provider_user_id, provider, first_name, last_name, email } = parse(search)

  const hookForm = useForm<any>({
    defaultValues: {
      provider,
      provider_user_id,
      first_name,
      last_name,
      email,
      subscribe_sms: false,
      subscribe_email: false
    },
    resolver: yupResolver(validationShema[step - 1])
  })

  const fetch = async (val?: string): Promise<Option[]> => {
    const data = await getFias({ query: val ?? "" })

    return data?.suggestions
      .filter((s: any) => {
        return s.data.fias_level !== "7" && s.data.fias_level !== "65" && !s.data.street && !s.data.house
      })
      .map((s: any) => ({
        value: s?.value,
        title: s?.value,
        key: s?.key,
        unrestricted_value: s?.unrestricted_value,
        city: s?.data.city_with_type,
        region: s?.data.region_with_type,
        area: s?.data.area_with_type,
        np: s?.data.settlement_with_type
      }))
  }

  useEffect(() => {
    getPublick("data/nursery_recorder/get", {}).then((r) => {
      if (r.status === ApiAnswerStatus.SUCCESS) {
        setNursery(r.data)
      }
    })
  }, [])

  useEffect(() => {
    if (isAuth) {
      post("/user/breeder/social_update", { provider, provider_user_id }).then((r) => {
        setMsg({
          message: Answer(r.message),
          onClose: () => {
            setMsg(undefined)

            history.push({
              pathname: "/profile",
              search: `#main`
            })
          }
        })
      })
    }
  }, [isAuth])

  const { recaptchaSitekey } = useSelector((state) => state.propsReducer)

  const onRecaptchaChange = (recaptchaToken: string | null) => {
    const f = formData

    f.append("recaptcha", recaptchaToken)

    signup(f)
      .then((res) => {
        const { status = 0, message: messages } = res || {}
        setProcessed(false)
        if (status === 1) {
          TrackEvent({
            eventCategory: "Registration",
            eventAction: "send_r_form",
            eventLabel: "success"
          })

          setMsg({
            status: 1,
            message: Answer(res.message),
            onClose: () => {
              setMsg(undefined)

              history.push({
                pathname: "/",
                search: `?signin&login=${f.get("phone")}`
              })
            }
          })
        } else {
          setMsg({
            status: 0,
            message: Answer(res.message),
            onClose: () => {
              setMsg(undefined)
            }
          })

          TrackEvent({
            eventCategory: "Registration",
            eventAction: "send_r_form",
            eventLabel: "unsuccess",
            eventContext: JSON.stringify(messages)
          })
        }
      })
      .catch(() => {
        setMsg({
          status: 0,
          message: Answer({ error: ["Сервер временно не доступен. Пожалуйста, попробуйте позже"] }),
          onClose: () => {
            setMsg(undefined)
          }
        })

        TrackEvent({
          eventCategory: "Registration",
          eventAction: "send_r_form",
          eventLabel: "unsuccess",
          eventContext: "Ошибка сервера. Попробуйте позже"
        })
      })
      .finally(() => {
        recaptchaRef?.reset()
        setProcessed(false)
      })
  }

  const onSubmit = (props: any) => {
    if (step < 3) {
      setStep(step + 1)
    } else {
      const fd = new FormData()
      const { address } = props
      // eslint-disable-next-line array-callback-return
      Object.keys(props).map((k: string) => {
        if (k === "image_file") {
          for (let i = 0; i < props[k].length; i++) {
            fd.append("image[]", props[k][i])
          }
        } else if (k === "register_at") {
          fd.append("register_at", moment(props[k]).format("yyyy-MM-DD"))
        } else {
          fd.append(k, typeof props[k] === "object" ? props[k].id || props[k] : props[k])
        }
      })
      fd.append("city", address?.city)
      fd.append("region", address?.region)
      fd.append("area", address?.area)
      fd.append("np", address?.np)

      setFormData(fd)
      setProcessed(true)
      recaptchaRef?.execute()
    }
  }

  const submitFailed = (err: any) => {
    TrackEvent({
      eventCategory: "Registration",
      eventAction: "send_r_form",
      eventLabel: "unsuccess",
      eventContext: `Некоторые поля не заполнены или заполнены неверно: ${Object.entries(err)
        .map(([key]) => key)
        .join(", ")}`
    })
  }

  const onRecaptchaExpired = () => {
    setProcessed(false)
  }

  return (
    <>
      {!isAuth && (
        <>
          <Box maxWidth={480} pb={1} style={{ borderBottom: "1px solid #DAD0CB", marginBottom: 20, margin: "0 auto" }}>
            <SocialAuth
              userProvider={[]}
              eventCategory="Registration"
              eventAction="over_social_r"
              label={<>Зарегистрироваться через соц.сеть</>}
              classNameLabel={classes.socialLabel}
            />
          </Box>

          <form onSubmit={hookForm.handleSubmit(onSubmit, submitFailed)} noValidate>
            {recaptchaSitekey && (
              <ReCAPTCHA
                ref={(ref: any) => setRecaptchaRef(ref)}
                size="invisible"
                sitekey={recaptchaSitekey}
                onExpired={onRecaptchaExpired}
                onChange={onRecaptchaChange}
              />
            )}

            <Box maxWidth={480} pt={5} style={{ margin: "0 auto" }}>
              <Box pb={3} fontSize={15} fontWeight={500} textAlign="right" color="#8C9297">
                Шаг {step} из 3
              </Box>

              {step === 1 && (
                <>
                  <Box>
                    <NameInput name="last_name" label="Фамилия" form={hookForm} placeholder="Пример: Иванов" />
                  </Box>
                  <Box>
                    <NameInput name="first_name" label="Имя" form={hookForm} placeholder="Пример: Иван" />
                  </Box>
                  <Box>
                    <SecondNameInput
                      name="middle_name"
                      label="Отчество"
                      form={hookForm}
                      placeholder="Пример: Иванович"
                    />
                  </Box>
                </>
              )}

              {step === 2 && (
                <>
                  <Box>
                    <PhoneInput name="phone" form={hookForm} placeholder="+7 (999) 000 00 00" />
                  </Box>
                  <Box>
                    <EmailInput name="email" form={hookForm} placeholder="Пример: examole@domain.com" />
                  </Box>
                  <Box style={{ margin: "0 auto" }}>
                    <AutocompleteDadataInputNew
                      label="Населенный пункт"
                      name="address"
                      form={hookForm}
                      fetch={fetch}
                      placeholder="Например: Москва"
                    />
                  </Box>
                  <Box>
                    <RefInput name="invite_ref" label="Код приглашения" placeholder="Пример: 123456" form={hookForm} />
                  </Box>
                </>
              )}

              {step === 3 && (
                <Box pb={4}>
                  <Box>
                    <NameInput
                      name="nursery_name"
                      label="Название питомника"
                      placeholder="Пример: ОскарДог"
                      form={hookForm}
                    />
                  </Box>

                  <Box>
                    <DropzoneInput label="Свидетельство о регистрации/родословные" form={hookForm} name="image_file" />
                  </Box>
                  <Box>
                    <DateInput name="register_at" form={hookForm} />
                  </Box>
                  <Box>
                    <AutocompleteSelect
                      placeholder="Не выбрано"
                      label="Регистрирующая организация"
                      form={hookForm}
                      name="nursery_recorder_id"
                      defaultOptions={nursery}
                    />
                  </Box>

                  <Box textAlign="left">
                    <CheckboxInput
                      required
                      label={
                        <>
                          Я прочитал и согласен с{" "}
                          {doc?.agreement ? (
                            <a rel="noreferrer" href={doc.agreement.primary_src} target="_blank">
                              Пользовательским соглашением
                            </a>
                          ) : (
                            "Пользовательским соглашением"
                          )}
                          , на обработку персональных данных{" "}
                        </>
                      }
                      name="rules"
                      form={hookForm}
                    />
                  </Box>
                  <Box textAlign="left">
                    <CheckboxInput label={<>Я согласен на смс рассылку</>} name="subscribe_sms" form={hookForm} />
                  </Box>

                  <Box textAlign="left">
                    <CheckboxInput label={<>Я согласен на e-mail рассылку</>} name="subscribe_email" form={hookForm} />
                  </Box>
                  <Box>
                    <SnackbarAlert form={hookForm} />
                  </Box>
                </Box>
              )}

              <Box>
                <Grid container justify="center">
                  <Grid item xs={6} sm={6}>
                    {step > 1 && (
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => {
                          if (step > 1) setStep(step - 1)
                        }}
                      >
                        Назад
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <SubmitButton
                      color="secondary"
                      variant="contained"
                      fullWidth
                      disabled={processed}
                      processed={processed}
                      title={step < 3 ? "Далее" : "Подтвердить"}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </form>
        </>
      )}
    </>
  )
}

export default SignupForm
