import { UseFormReturn } from "react-hook-form"
import { autocomleteInputOff } from "./autocomlete-input"
import GenericTextInput from "./genericTextInput"

type Props = {
  form: any
  name: string
  label?: string
  placeholder?: string
  disabled?: boolean
}

const SecondNameInput: React.FC<Props> = ({ form, name, placeholder, label = "Имя", disabled = false }) => {
  const { register, formState }: UseFormReturn = form

  return (
    <GenericTextInput
      register={register(name, {
        pattern: { value: /^[a-zA-Zа-яёА-ЯЁ\s-]{2,}$/i, message: "Поле заполнено неверно" },
        maxLength: {
          value: 20,
          message: "Максимальная длинна 20 символов"
        }
      })}
      InputProps={{
        inputProps: { ...(autocomleteInputOff as any) }
      }}
      label={label}
      placeholder={placeholder}
      helperText={formState.errors[name]?.message}
      disabled={disabled}
    />
  )
}

export default SecondNameInput
