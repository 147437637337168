import { useEffect } from "react"
import { parse, ParsedQuery } from "query-string"
import useReactRouter from "use-react-router"
import { usePrevious } from "react-use"
import { useDispatch } from "react-redux"
import { SET_PROPS } from "../store/props/types"
import SignInModal, { modalName as SigninModalName } from "../modal/signin"
import SignUnModal, { modalName as SignupModalName } from "../modal/signup"
import { ChangePasswordModal, modalName as ChangePasswordModalName } from "../modal/change-password"
import { BuyerModal, modalName as BuyerAddModalName } from "../page/profile/modal/buyer"
// eslint-disable-next-line import/no-named-as-default
import ForgotModal, { modalName as ForgotModalName } from "../modal/forgot"
import TrackEvent from "../components/GAnalitics"
import { useSelector } from "../hooks"
import { ExhibitionModal, modalName as ExhibitionModalName } from "../page/profile/modal/exhibition"
import { OffspringModal, modalName as OffspringModalName } from "../page/profile/modal/offspring/offspringModal"
import ViewerModal, { modalName as ViewerModalName } from "../modal/viewer/viewer"

const ModalRoot: React.FC<{ loadInfo: any }> = ({ loadInfo }) => {
  const { location } = useReactRouter()
  const { pathname, search, hash } = location
  const prevLocation = usePrevious({ location: { pathname, search, hash } })
  const dispatch = useDispatch()
  const { isAuth } = useSelector((state) => state.propsReducer)

  const openModal = (modal: string, query: ParsedQuery) => {
    dispatch({
      type: SET_PROPS,
      payload: {
        modal: {
          [modal]: {
            show: true,
            params: {
              ...query
            }
          }
        }
      }
    })
  }

  const openModalAuth = (modal: string, query: ParsedQuery) => {
    if (isAuth) {
      openModal(modal, query)
    } else {
      // history.push({ search: "" })
    }
  }

  const openModalNoAuth = (modal: string, query: ParsedQuery) => {
    if (!isAuth) {
      openModal(modal, query)
    } else {
      // history.push("/profile")
    }
  }

  useEffect(() => {
    const query = parse(search)

    if (prevLocation?.location.search !== search) {
      dispatch({
        type: SET_PROPS,
        payload: {
          modal: {
            [SigninModalName]: { show: false },
            [SignupModalName]: { show: false },
            [ForgotModalName]: { show: false },
            [BuyerAddModalName]: { show: false },
            [ExhibitionModalName]: { show: false },
            [OffspringModalName]: { show: false },
            [ViewerModalName]: { show: false },
            [ChangePasswordModalName]: { show: false }
          }
        }
      })

      if ("signin" in query) {
        openModalNoAuth(SigninModalName, query)
      } else if ("signup" in query) {
        openModalNoAuth(SignupModalName, query)
      } else if ("forgot" in query) {
        openModalNoAuth(ForgotModalName, query)
      } else if ("buyer-add" in query) {
        openModalAuth(BuyerAddModalName, query)
      }
    }
  }, [search])

  useEffect(() => {
    if (prevLocation?.location.pathname !== pathname) {
      TrackEvent({ eventCategory: "Pageview", eventAction: "open_page", eventLabel: pathname })
    }
  }, [pathname])

  return (
    <>
      <SignInModal />
      <ViewerModal />
      <SignUnModal />
      <ForgotModal />
      <ChangePasswordModal
        onClose={() => {
          loadInfo()
        }}
      />
      <BuyerModal
        onClose={() => {
          loadInfo()
        }}
      />
      <ExhibitionModal
        onClose={() => {
          loadInfo()
        }}
      />

      <OffspringModal
        onClose={() => {
          loadInfo()
        }}
      />
    </>
  )
}

export default ModalRoot
