import useReactRouter from "use-react-router"
import { useEffect, useState } from "react"
import Dialog from "../../components/dialog"
import TrackEvent from "../../components/GAnalitics"
import { useSelector } from "../../hooks"
import SignupForm from "./form"
import { GlobalAlertModal } from "../globalAlert"
import { Msg } from "../globalAlert/alert"

export const modalName = "SignupModal"

const SignUpModal = () => {
  const { history } = useReactRouter()
  const { modal } = useSelector((state) => state.propsReducer)
  const { show = false } = modal[modalName] || {}
  const [msg, setMsg] = useState<Msg>()

  const onClose = () => {
    history.push({ search: "" })
  }

  useEffect(() => {
    if (msg && msg.status === 1) {
      onClose()
    }
  }, [msg])

  return (
    <>
      <GlobalAlertModal msg={msg} />
      <Dialog
        title="Регистрация"
        scroll="body"
        maxWidth="sm"
        onOpen={() => {
          TrackEvent({ eventCategory: "Registration", eventAction: "open_r_form" })
        }}
        onCloseDialog={onClose}
        name={modalName}
        open={show}
      >
        <SignupForm setMsg={setMsg} />
      </Dialog>
    </>
  )
}

export default SignUpModal
