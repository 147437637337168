import { UseFormReturn, Controller } from "react-hook-form"

import { DropzoneDialog } from "material-ui-dropzone"
import { useState } from "react"
import { Box, Button, IconButton, InputAdornment, makeStyles, TextField } from "@material-ui/core"
import { CloseOutlined } from "@material-ui/icons"
import { GlobalAlertModal } from "../../modal/globalAlert"
import { Msg } from "../../modal/globalAlert/alert"
import Answer from "../../utils/answer"

type Props = {
  form: any
  name: string
  label?: string
  disabled?: boolean
  placeholder?: string
}

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    "& input": {
      paddingLeft: 140
    },
    "&>div": {
      paddingRight: "0px!important"
    }
  },
  btn: {
    position: "absolute",
    left: 6,
    top: 6
  }
}))

const DropzoneInput: React.FC<Props> = ({
  form,
  name,
  label = "Добавить файлы",
  placeholder = "Загрузите или перетяните сюда файл"
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const [files, setFiles] = useState<any[]>([])
  const [msg, setMsg] = useState<Msg>()
  const { control }: UseFormReturn = form
  const classes = useStyles()

  return (
    <Box>
      <GlobalAlertModal msg={msg} />

      <Controller
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
          <Box className={classes.root}>
            <DropzoneDialog
              maxWidth="xs"
              clearOnUnmount={false}
              filesLimit={5}
              showFileNamesInPreview={false}
              dialogTitle={label}
              acceptedFiles={["image/jpeg", "application/pdf", "image/png"]}
              cancelButtonText="отмена"
              submitButtonText="выбрать"
              previewText="Предпросмотр:"
              dropzoneText={placeholder}
              getFileLimitExceedMessage={(fileName) => {
                return `Максимальное кол-во файлов: ${fileName}`
              }}
              getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => {
                console.log(rejectedFile, acceptedFiles, maxFileSize, error)
                // TODO: дописать ошибку на размер файла и на тип.
                if (!acceptedFiles.includes(rejectedFile.type)) {
                  return `Выберите файлы в формате jpg, jpeg, png или pdf`
                }

                if (maxFileSize < rejectedFile.size) {
                  return `Размер файла не должен превышать ${maxFileSize / 1000000} Мб`
                }

                return `Ошибка добавления файла ${rejectedFile.name}`
              }}
              onAlert={(message, variant) => {
                console.log(message, `e:${variant}`)

                if (variant === "error") {
                  setMsg({
                    status: 0,
                    message: Answer(message),
                    onClose: () => {
                      setMsg(undefined)
                    }
                  })
                }
              }}
              maxFileSize={10000000}
              fullWidth={false}
              open={open}
              onClose={() => {
                setOpen(false)
              }}
              onSave={(f) => {
                console.log("Files:", f)
                setOpen(false)
                setFiles(f)
                field.onChange(f)
              }}
              showPreviews
              showAlerts={false}
              previewGridProps={{ container: { spacing: 1, direction: "row" } }}
            />
            <Box>
              <TextField
                label={label}
                fullWidth
                color="primary"
                variant="filled"
                disabled
                helperText={error?.message}
                className={classes.root}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {files.length > 0 && (
                        <IconButton
                          onClick={() => {
                            setFiles([])
                            field.onChange(undefined)
                          }}
                          onMouseDown={(event: any) => {
                            event.preventDefault()
                          }}
                          style={{ fontSize: "120%" }}
                          color="secondary"
                          aria-label="toggle password visibility"
                        >
                          <CloseOutlined />
                        </IconButton>
                      )}
                    </InputAdornment>
                  )
                }}
                value={`Выбрано файлов: ${files.length}`}
              />
              <Button
                className={classes.btn}
                type="button"
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => setOpen(true)}
              >
                Добавить фото
              </Button>
            </Box>
          </Box>
        )}
        name={name}
        control={control}
        defaultValue=""
      />
    </Box>
  )
}

export default DropzoneInput
