import { useEffect, useState } from "react"
import { Box, Table, TableRow, TableCell, TableHead, TableBody, Grid, Hidden } from "@material-ui/core"
import Moment from "react-moment"
import Pagination from "@material-ui/lab/Pagination"
import { PrizeUser } from "../../types/prize"
import BallView from "../../components/ball/ball"

const countArray = (ar: any) => (ar ? ar.length : 0)

const PrizeList: React.FC<{ items: PrizeUser[] }> = ({ items = [] }) => {
  const [page, setPage] = useState<number>(1)
  const perPage = 5

  const handleChange = (_event: any, value: number) => {
    setPage(value)
  }

  const [pu, setPu] = useState<PrizeUser[]>([])

  useEffect(() => {
    if (items) setPu([...items].reverse())
  }, [items])

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <Hidden xsDown>
              <TableCell>Дата</TableCell>
              <TableCell>НАИМЕНОВАНИЕ ПРИЗА</TableCell>
              <TableCell>СТАТУС ПРИЗА</TableCell>
              <TableCell>СТОИМОСТЬ</TableCell>
              {/* <TableCell>КОД</TableCell> */}
            </Hidden>
          </TableRow>
        </TableHead>

        <TableBody>
          {pu.length > 0 ? (
            pu.slice(perPage * (page - 1), page * perPage).map(({ id, title, pivot }: PrizeUser) => (
              <TableRow key={`prizeslist-${id}`}>
                <Hidden xsDown>
                  <TableCell>
                    <Moment format="DD.MM.YYYY">{pivot.created_at}</Moment>
                  </TableCell>

                  <TableCell>{title}</TableCell>

                  <TableCell>{pivot.breeder_priz_status?.title}</TableCell>

                  <TableCell>
                    <BallView>{pivot.score * pivot.amount}</BallView>
                  </TableCell>

                  {/* <TableCell> */}
                  {/*  {breeder_priz_type?.key === "sert" && */}
                  {/*    pivot.promo.map(({ code, link }, idx) => ( */}
                  {/*      <Box key={idx}> */}
                  {/*        {code && <Box>{code}</Box>} */}
                  {/*        {link && ( */}
                  {/*          <CustomLink target="_blank" href={link}> */}
                  {/*            Скачать */}
                  {/*          </CustomLink> */}
                  {/*        )} */}
                  {/*      </Box> */}
                  {/*    ))} */}
                  {/*  {pivot.promo.length === 0 && <Box>-</Box>} */}
                  {/* </TableCell> */}
                </Hidden>

                <Hidden smUp>
                  <TableCell>
                    <Box textAlign="left" fontSize={14}>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Grid container>
                            <Grid item className="m_cell_title">
                              Дата
                            </Grid>
                            <Grid item xs={12} className="m_cell">
                              <Moment format="DD.MM.YYYY">{pivot.created_at}</Moment>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={6}>
                          <Grid container>
                            <Grid item className="m_cell_title">
                              Статус приза
                            </Grid>
                            <Grid item xs={12} className="m_cell">
                              {pivot.breeder_priz_status?.title}
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={6}>
                          <Grid container>
                            <Grid item className="m_cell_title">
                              Стоимость
                            </Grid>
                            <Grid item xs={12} className="m_cell">
                              <BallView style={{ justifyContent: "flex-start" }}>{pivot.score * pivot.amount}</BallView>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* <Grid item xs={6}> */}
                        {/*  <Grid container> */}
                        {/*    <Grid item className="m_cell_title"> */}
                        {/*      Код */}
                        {/*    </Grid> */}
                        {/*    <Grid item xs={12} className="m_cell"> */}
                        {/*      {breeder_priz_type?.key === "sert" && */}
                        {/*        pivot.promo.map(({ code, link }, idx) => ( */}
                        {/*          <Box key={idx}> */}
                        {/*            {code && <Box>{code}</Box>} */}
                        {/*            {link && ( */}
                        {/*              <CustomLink target="_blank" href={link}> */}
                        {/*                Скачать */}
                        {/*              </CustomLink> */}
                        {/*            )} */}
                        {/*          </Box> */}
                        {/*        ))} */}
                        {/*      {pivot.promo.length === 0 && <Box>-</Box>} */}
                        {/*    </Grid> */}
                        {/*  </Grid> */}
                        {/* </Grid> */}

                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item className="m_cell_title">
                              Наименование приза
                            </Grid>
                            <Grid item xs={12} className="m_cell">
                              {title}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                </Hidden>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5}>Здесь будет информация о призах</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {countArray(items) > perPage && (
        <Box pt={4} textAlign="center">
          <Pagination
            page={page}
            variant="text"
            onChange={handleChange}
            count={Math.ceil(countArray(items) / perPage)}
          />
        </Box>
      )}
    </>
  )
}

export default PrizeList
