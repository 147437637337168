import { UseFormReturn } from "react-hook-form"
import moment from "moment"
import { numericInput } from "./autocomlete-input"
import GenericTextInput from "./genericTextInput"

type Props = {
  form: any
  name: string
  label?: string
  disabled?: boolean
}

const DateInput: React.FC<Props> = ({ form, name, label = "Дата регистрации", disabled = false }) => {
  const { register, formState }: UseFormReturn = form
  return (
    <GenericTextInput
      placeholder={`Пример: ${moment().format("DD.MM.YYYY")}`}
      register={register(name, {
        required: true,
        pattern: /^([12]\d{3}.(0[1-9]|1[0-2]).(0[1-9]|[12]\d|3[01]))$/i
      })}
      InputProps={{
        inputProps: {
          ...(numericInput as any),
          max: moment().format("yyyy-MM-DD"),
          min: moment().add(-20, "y").format("yyyy-MM-DD")
        }
      }}
      type="date"
      label={label}
      helperText={formState.errors[name]?.message}
      disabled={disabled}
    />
  )
}

export default DateInput
