import { UseFormReturn } from "react-hook-form"
import { numericInput } from "./autocomlete-input"
import GenericTextInput from "./genericTextInput"

type Props = {
  form: any
  name: string
  label?: string
  placeholder?: string
  disabled?: boolean
}

const RefInput: React.FC<Props> = ({ form, name, placeholder, label = "Код приглашения", disabled = false }) => {
  const {
    register,
    clearErrors,

    formState: { errors }
  }: UseFormReturn = form

  return (
    <GenericTextInput
      register={register(name, {
        pattern: { value: /^[0-9]{8}$/i, message: "Поле заполнено неверно" },
        maxLength: { value: 8, message: "Поле заполнено неверно" },
        minLength: { value: 8, message: "Поле заполнено неверно" }
      })}
      InputProps={{
        inputProps: { ...(numericInput as any), maxlength: 8 }
      }}
      onChange={(e: any) => {
        console.log(errors[name], !e?.target?.value)

        if (!e?.target?.value) {
          clearErrors(name)
        }
      }}
      label={label}
      placeholder={placeholder}
      helperText={errors[name]?.message}
      disabled={disabled}
    />
  )
}

export default RefInput
