export const autocomleteInputOff = {
  autoComplete: "off",
  list: "autocompleteOff",
  autocomplete: "off",
  autoCorrect: "off",
  spellCheck: "false",
  autoCapitalize: "on"
}

export const emailInput = {
  autoComplete: "nope",
  list: "autocompleteOff",
  autocomplete: "off",
  autoCorrect: "off",
  spellCheck: "false",
  inputMode: "email",
  autoCapitalize: "off"
}

export const decimalInput = {
  autoComplete: "nope",
  list: "autocompleteOff",
  autoCorrect: "off",
  spellCheck: "false",
  inputMode: "decimal",
  autoCapitalize: "off"
}

export const numericInput = {
  autoComplete: "off",
  autocomplete: "off",
  list: "autocompleteOff",
  autoCorrect: "off",
  spellCheck: "false",
  inputMode: "numeric",
  autoCapitalize: "off"
}

export const passwordInput = {
  autoComplete: "off",
  autocomplete: "off",
  list: "autocompleteOff",
  autoCorrect: "off",
  spellCheck: "false",
  autoCapitalize: "off"
}
