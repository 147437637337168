import axios, { AxiosRequestConfig } from "axios"
import { ApiAnswer, ApiAnswerStatus } from "./types"

// eslint-disable-next-line @typescript-eslint/no-var-requires
const ls = require("local-storage")

enum TOKEN {
  PUBLIC = "token_public",
  PRIVATE = "token_private"
}

const { REACT_APP_API_URL = "/", REACT_APP_API_AUTH } = process.env
const axiosClient = axios.create({
  baseURL: `${REACT_APP_API_URL.replace(/^\/+/, "")}/api/`,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-Requested-With": "XMLHttpRequest",
    "X-Access-Token": `Basic ${REACT_APP_API_AUTH}`
  }
})

const apiFetchData = async (config: AxiosRequestConfig): Promise<ApiAnswer> => {
  const result: ApiAnswer = await axiosClient
    .request(config)
    .then((r) => {
      return r.data
    })
    .catch((e) => {
      if (e?.response?.status === ApiAnswerStatus.NEED_CONFIRM) {
        return {
          status: ApiAnswerStatus.SUCCESS,
          data: e.response?.data?.data,
          message: (typeof e.response?.data?.message === "string"
            ? { alert: [e.response?.data?.message] }
            : e.response?.data?.message) || { alert: [e.message] }
        }
      }
      return (
        e.response?.data ?? {
          status: ApiAnswerStatus.ERROR,
          message: (typeof e.response?.data?.message === "string"
            ? { alert: [e.response?.data?.message] }
            : e.response?.data?.message) || { alert: [e.message] }
        }
      )
    })
  return result
}

const reFetchPrivateAPIToken = async (refreshToken: string) => {
  return apiFetchData({
    url: "/oauth/token",
    method: "post",
    data: {
      refresh_token: refreshToken,
      grant_type: "refresh_token"
    }
  })
}

const fetchPrivateAPIToken = async (username: string, password: string) => {
  const result = await apiFetchData({
    url: "/oauth/token",
    method: "post",
    data: {
      scope: "*",
      grant_type: "password",
      username,
      password
    }
  })

  if (result.status === ApiAnswerStatus.SUCCESS) {
    ls.set(TOKEN.PRIVATE, result.data)
  }

  return result
}

const fetchPrivateAPITokenSoc = async (access_token: any, provider: any) => {
  const result = await apiFetchData({
    url: "/oauth/token",
    method: "post",
    data: {
      access_token,
      grant_type: "social",
      provider
    }
  })

  if (result.status === ApiAnswerStatus.SUCCESS) {
    ls.set(TOKEN.PRIVATE, result.data)
  }

  return result
}

const withPrivateAPIToken = async (config: AxiosRequestConfig) => {
  const token = ls.get(TOKEN.PRIVATE)
  if (token && token.access_token) {
    const test = await apiFetchData({
      ...config,
      headers: { "X-Access-Token": `Bearer ${token.access_token}` }
    }).then(async (res) => {
      if (res?.status !== ApiAnswerStatus.UNAUTHENTICATED) return res

      return reFetchPrivateAPIToken(token.refresh_token).then(async (result) => {
        if (result.status !== ApiAnswerStatus.SUCCESS) {
          ls.remove(TOKEN.PRIVATE)
          return result
        }
        ls.set(TOKEN.PRIVATE, result.data)

        return apiFetchData({
          ...config,
          headers: {
            "X-Access-Token": `Bearer ${result.data.access_token}`
          }
        })
      })
    })
    return test
  }
  return apiFetchData(config)
}

const fetchPublicAPIToken = async () => {
  const result = await apiFetchData({
    url: "/oauth/token",
    method: "post",
    data: {
      grant_type: "client_credentials"
    }
  })
  ls.set(TOKEN.PUBLIC, result.data)
  return result
}

const withPublicAPIToken = async (config: AxiosRequestConfig) => {
  const token = ls.get(TOKEN.PUBLIC)
  if (token && token.access_token) {
    const test = await apiFetchData({
      ...config,
      headers: { "X-Access-Token": `Bearer ${token.access_token}` }
    }).then((res) => {
      if (res?.status !== ApiAnswerStatus.UNAUTHENTICATED) return res
      ls.remove(TOKEN.PUBLIC)
      return fetchPublicAPIToken().then((r) => {
        if (r?.status === ApiAnswerStatus.SUCCESS) {
          return apiFetchData({
            ...config,
            headers: {
              "X-Access-Token": `Bearer ${r.data.access_token}`
            }
          })
        }
        return res
      })
    })

    return test
  }
  return fetchPublicAPIToken().then((res) => {
    if (res?.status === ApiAnswerStatus.SUCCESS) {
      return apiFetchData({
        ...config,
        headers: {
          "X-Access-Token": `Bearer ${res.data.access_token}`
        }
      })
    }
    return res
  })
}

const signout = async () => {
  return withPrivateAPIToken({
    url: "/user/breeder/logout",
    method: "get"
  }).then((_r) => {
    ls.remove(TOKEN.PRIVATE)
  })
}

export { fetchPrivateAPITokenSoc, withPrivateAPIToken, withPublicAPIToken, fetchPrivateAPIToken, signout, apiFetchData }
