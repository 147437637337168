export function checkIfFilesAreTooBig(files?: any): boolean {
  let valid = true
  if (files) {
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i)
      const size = file.size / 1024 / 1024
      if (size > 10) {
        valid = false
      }
    }
  }
  return valid
}

export function checkIfFilesAreCorrectType(files?: any): boolean {
  let valid = true
  if (files) {
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i)
      if (!["image/jpeg", "image/png"].includes(file.type)) {
        valid = false
      }
    }
  }
  return valid
}

export function checkIfFilesSelect(files?: any): boolean {
  return files?.length > 0
}
