import * as yup from "yup"
import { ObjectShape } from "yup/lib/object"
import russianRules from "../../utils/russianRules"
import { checkIfFilesAreCorrectType, checkIfFilesAreTooBig, checkIfFilesSelect } from "../../utils/validationRules"

export const phone = yup.string().matches(/^9\d{9}$/i, { message: "Указан некорректный номер телефона" })
export const email = yup
  .string()
  .typeError("Поле не заполнено")
  .email("Указан некорректный e-mail")
  .required("Поле не заполнено")
export const string_required = yup.string().typeError("Поле не заполнено").required("Поле не заполнено")
// export const date = yup.string().typeError("Поле не заполнено").required("Укажите корректную дату")
export const date = yup
  .date()
  .typeError("Поле не заполнено")
  .required("Укажите корректную дату")
  .max(new Date(), "Укажите корректную дату")

export const number_required = yup.number().typeError("Укажите число").required("Поле не заполнено")
export const nursery_recorder_id = yup
  .object()
  .typeError("Выберите организацию из списка ")
  .required("Выберите организацию из списка ")
export const breed_id = yup
  .object()
  .typeError("Выберите породу из списка")
  .required("Выберите породу из списка")
  .test("number", "Выберите породу из списка", (i) => i?.id)
export const breed_species_id = yup
  .object()
  .typeError("Выберите питомца из списка")
  .required("Выберите питомца из списка")
  .test("number", "Выберите питомца из списка", (i) => i?.id)
export const boolean = yup.boolean().default(false)
export const boolean_required = yup.boolean().oneOf([true], "Необходимо согласиться с условиями").default(false)

export const imageDropzone = yup
  .array()
  .typeError("Выберите файлы в формате jpg или jpeg")
  .required("Выберите файлы в формате jpg или jpeg")

export const image = yup
  .mixed()
  .required("Выберите файлы в формате jpg или jpeg")
  .test("select-file", "Выберите файлы в формате jpg или jpeg", checkIfFilesSelect)
  .test("is-correct-file", "Выберите файлы в формате jpg или jpeg", checkIfFilesAreCorrectType)
  .test("is-big-file", "Размер файла превышает 10 мб", checkIfFilesAreTooBig)

export const address = yup
  .object()
  .typeError("Выберите регион и населенный пункт")
  .required("Выберите регион и населенный пункт")
  .test("city-or-np", "Выберите регион и населенный пункт", (a) => a?.city || a?.np)
export const invite_ref = yup
  .string()
  .matches(/^[0-9]{8}$/i, { message: "Код должен состоять из 8 цифр", excludeEmptyString: true })
export const password = yup.string().min(6, "Введите минимум 6 символов").max(32).required("Поле не заполнено")
export const name = yup
  .string()
  .required("Поле не заполнено")
  .min(2, "Минимальная длина поля 2 символа")
  .max(30)
  .matches(/^[а-яёА-ЯЁa-zA-Z\s-]*$/i, "Только буквенные символы")

export const middle_name = yup.string().matches(/^[а-яёА-ЯЁa-zA-Z\s-]{2,30}$/i, {
  message: "Только буквенные символы",
  excludeEmptyString: true
})

yup.setLocale({
  mixed: {
    required: "Поле не заполнено",
    default: "Поле заполнено неверно",
    notType: "Поле заполнено неверно"
  },

  string: {
    email: russianRules("Указан некорректный e-mail") as string,
    min: ({ min }) => `Минимальная длина поля ${min} символов`,
    max: ({ max }) => `Максимальная длина поля ${max} символов`,
    matches: "Поле заполнено неверно",
    length: ({ length }) => `Должно быть ровно ${length} символов`
  },

  number: {
    min: ({ min }) => `Минимальное число: ${min}`,
    max: ({ max }) => `Максимальное число: ${max}`,
    lessThan: ({ less }) => `Число должно быть меньше чем ${less}`,
    moreThan: ({ more }) => `Число должно быть больше чем ${more}`,
    positive: "Ожидается положительное число",
    negative: "Ожидается отрицательное число"
  },

  date: {
    min: ({ min }) => `Минимальная дата в акции ${min}`,
    max: ({ max }) => `Максимальная дата в акции ${max}`
  }
})

// eslint-disable-next-line import/prefer-default-export
export const validation = (s: ObjectShape) => yup.object().shape(s)
