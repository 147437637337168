import { Box, Button, Grid, makeStyles, Theme } from "@material-ui/core"
import { useState } from "react"

const useStyles = makeStyles(({ breakpoints: { down } }: Theme) => ({
  root: {
    background: "#F0EDEC",
    textAlign: "center",
    fontSize: 22,
    height: "100%",
    padding: 20,
    [down("xs")]: { width: "calc(100% + 32px)", marginLeft: -16, padding: "20px 0" }
  },

  codeTitle: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: "uppercase"
  },

  code: {
    fontWeight: 700,
    fontSize: 36,
    letterSpacing: "0.25em",
    [down("md")]: { letterSpacing: "0.0em" },
    [down("sm")]: { fontSize: 25 }
  },

  copy: { fontSize: 12 }
}))

const Friends: React.FC<{ code: string }> = ({ code }) => {
  const classes = useStyles()
  const [copy, setCopy] = useState<boolean>(false)

  const copyToClipboard = (content: any) => {
    const el = document.createElement("textarea")
    el.value = content
    document.body.appendChild(el)
    el.select()
    document.execCommand("copy")
    document.body.removeChild(el)
  }
  return (
    <Box pt={4} pb={5}>
      <Grid container alignItems="stretch" spacing={2}>
        <Grid item xs={12} sm={7}>
          <Box pt={4} className={classes.root}>
            <Grid container justify="center" alignItems="center" style={{ height: "100%" }}>
              <Grid item>
                <Box pb={2}>
                  Пригласите друга-заводчика в программу лояльности AlphaPet<sup>®</sup> Breeders и получите
                  500&nbsp;баллов после регистрации и первой покупки друга.
                </Box>
                <Box>
                  <b>Отправьте код другу-заводчику.</b>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Box className={classes.root}>
            <Grid container justify="center" alignItems="center" style={{ height: "100%" }}>
              <Grid item>
                <Box className={classes.codeTitle} pb={1}>
                  Ваш код приглашения
                </Box>
                <Box className={classes.code} pb={3}>
                  {code}
                </Box>
                <Box>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      copyToClipboard(code)
                      setCopy(true)
                    }}
                  >
                    Скопировать код
                  </Button>
                </Box>
                <Box className={classes.copy}>{copy && "Код скопирован"}&nbsp;</Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Friends
