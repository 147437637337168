import { useEffect, useState } from "react"
import {
  Box,
  Grid,
  useTheme,
  useMediaQuery,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core"
import Container from "@material-ui/core/Container"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
// eslint-disable-next-line import/no-extraneous-dependencies
import classNames from "classnames"
import { useDispatch } from "react-redux"
import useReactRouter from "use-react-router"
import PageContainer from "../../layout/page-container"
import Profile from "../profile/profile"
import Exhibition from "../exhibition/exhibition"
import Advertising from "../advertising/advertising"
import MyLink from "../../layout/menu/myLink"
import { User } from "../../../types/user"
import { useSelector } from "../../../hooks"
import AuthAction from "../../../store/auth/action"
import CustomLink from "../../../components/link"
import { File, SET_PROPS } from "../../../store/props/types"
import Offspring from "../offspring/offspring"
import Owner from "../owner/owner"
import { post } from "../../../api/actions"
import SubmitButton from "../../../components/submit-button"
import { modalName as ChangePasswordModal } from "../../../modal/change-password"
import { GlobalConfirmAlert, Msg as ConfirmMsg } from "../../../modal/globalConfirmAlert/globalConfirmAlert"
import useStyles from "./style"
// import Promo from "../promo/promo"

const TestStatus = (Components: any, user: User, need: string, loadInfo: any, rules?: File) => {
  if (user?.breeder_level_current?.breeder_level_option_current.filter((v) => v.key === need).length > 0) {
    return <Components user={user} loadInfo={loadInfo} />
  }

  if (need === "litter_gift") {
    return (
      <Box textAlign="center">
        <Box pt={4}>
          Чтобы получить доступ к&nbsp;опциям необходимо совершить покупки корма ТМ&nbsp;AlphaPet
          <br /> на&nbsp;сумму не&nbsp;менее 10&nbsp;000&nbsp;р. в&nbsp;месяц.
          <br />
          {rules && (
            <Box>
              Подробнее по{" "}
              <a rel="noreferrer" href={rules.primary_src} target="_blank">
                ссылке
              </a>
              .
            </Box>
          )}
        </Box>
        <Box pt={4} pb={4}>
          {" "}
          Опции будут доступны в&nbsp;течение 10&nbsp;дней после оплаты заказа.
        </Box>
      </Box>
    )
  }

  return (
    <Box textAlign="center">
      <Box pt={4}>
        Чтобы получить доступ к&nbsp;опциям необходимо совершить покупку любого корма ТМ&nbsp;AlphaPet.
        <br />
        {rules && (
          <Box>
            Подробнее по{" "}
            <a rel="noreferrer" href={rules.primary_src} target="_blank">
              ссылке
            </a>
            .
          </Box>
        )}
      </Box>
      <Box pt={4} pb={4}>
        Опции будут доступны в&nbsp;течение 10&nbsp;дней после оплаты заказа.
      </Box>
    </Box>
  )
}

const Animate = ({ children, key }: { children: any; key: string }) => {
  return (
    <Box key={key} className="animate__animated animate__fadeIn animate__faster">
      {children}
    </Box>
  )
}

// eslint-disable-next-line react/require-default-props
const Case = ({ h, user, loadInfo, rules }: { h: string; user: User; loadInfo: any; rules?: File }) => {
  switch (h) {
    case "#main":
      return (
        <Animate key={h}>
          <Profile user={user} loadInfo={loadInfo} />
        </Animate>
      )
    case "#offspring":
      return <Animate key={h}>{TestStatus(Offspring, user, "offspring", loadInfo, rules)}</Animate>
    case "#owner":
      return <Animate key={h}>{TestStatus(Owner, user, "offspring", loadInfo, rules)}</Animate>
    case "#exhibition":
      return <Animate key={h}>{TestStatus(Exhibition, user, "exhibitions", loadInfo, rules)}</Animate>
    case "#advertising":
      return <Animate key={h}>{TestStatus(Advertising, user, "advertising_social", loadInfo, rules)}</Animate>
    // case "#promo":
    //   return <Animate key={h}>{TestStatus(Promo, user, "offspring", loadInfo, rules)}</Animate>

    default:
      return <></>
  }
  return <></>
}

const Page = () => {
  const classes = useStyles()
  const router = useReactRouter()
  const { location } = router
  const [processed, setProcessed] = useState<boolean>(false)
  const [expandedA, setExpandedA] = useState<boolean>(false)

  const [confirmMsg, setConfirmMsg] = useState<ConfirmMsg>()

  const { data } = useSelector((state) => state.authReducer)
  const { doc } = useSelector((state) => state.propsReducer)
  const { rules_at } = data || {}

  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up("md"))
  const dispatch = useDispatch()

  if (router.location.hash === "") {
    router.history.push({ hash: "#main" })
  }

  const loadInfo = () => {
    dispatch(AuthAction())
  }
  const rules = () => {
    setProcessed(true)

    post("/user/breeder/rules_confirm", {})
      .then(() => {
        loadInfo()

        setConfirmMsg({
          title: "Сменить пароль?",
          message:
            "Для удобства авторизации Вы можете придумать свой пароль, воспользовавшись сервисом смены пароля. Сменить пароль?",
          onOk: () => {
            dispatch({
              type: SET_PROPS,
              payload: { modal: { [ChangePasswordModal]: { show: true } } }
            })
            setConfirmMsg(undefined)
          },
          onClose: () => {
            setConfirmMsg(undefined)
          }
        })
      })
      .catch(() => {})
      .finally(() => {
        setProcessed(false)
      })
  }

  const validateAuth = () => {
    return dispatch(
      AuthAction(
        () => {
          dispatch({
            type: SET_PROPS,
            payload: { isAuth: true }
          })
        },
        () => {
          dispatch({
            type: SET_PROPS,
            payload: { isAuth: false }
          })
        }
      )
    )
  }

  useEffect(() => {
    loadInfo()
    validateAuth()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [router.location.hash])

  const submenu = [
    { pathname: "/profile", hash: "#main", name: "Профиль" },
    { pathname: "/profile", hash: "#offspring", name: "Помёты" },
    { pathname: "/profile", hash: "#exhibition", name: "Выставки" },
    { pathname: "/profile", hash: "#advertising", name: "Реклама в соц.сетях" }
    // { pathname: "/profile", hash: "#promo", name: "Мой рейтинг" }
  ]

  if (rules_at === null) {
    return (
      <PageContainer>
        <Container maxWidth="md">
          <Box pt={6} pb={6} textAlign="center">
            Для участия в Программе лояльности и получения доступа к личному кабинету, ознакомьтесь, пожалуйста,&nbsp;с
            <br />
            {doc?.rules ? (
              <CustomLink href={doc.rules.primary_src} className={classes.link} target="_blank">
                Правилами Программы Лояльности
              </CustomLink>
            ) : (
              "Правилами Программы Лояльности"
            )}
          </Box>
          <Box textAlign="center" pb={7}>
            <SubmitButton
              color="secondary"
              variant="contained"
              onClick={rules}
              disabled={processed}
              processed={processed}
              title="Я согласен с правилами"
            />
          </Box>
        </Container>
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <GlobalConfirmAlert msgs={confirmMsg} />

      <Container maxWidth={false}>
        <Grid container justify="center" spacing={4}>
          <Grid item xs={12} sm={12} md={3}>
            {!md && (
              <>
                <Accordion
                  expanded={expandedA}
                  onChange={(_event: any, expanded: any) => {
                    setExpandedA(expanded)
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Box className={classNames(classes.submenu, "active")}>
                      {submenu.map((i) => {
                        if (i.hash === router.location.hash) {
                          return i.name
                        }
                        return null
                      })}
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box px={2} width="100%">
                      {submenu.map((i) => {
                        return (
                          <Box py={1} key={i.hash}>
                            <Link
                              className={classNames(classes.submenu, location.hash === i.hash ? "actives" : "s")}
                              onClick={() => {
                                setExpandedA(false)
                              }}
                              component="a"
                              href={`${i.pathname}/${i.hash}`}
                            >
                              {i.name}
                            </Link>
                          </Box>
                        )
                      })}
                      {doc?.price && (
                        <Box pt={3} width="100%">
                          <img src="/imgs/pdf.svg" style={{ marginBottom: -10 }} alt="" />{" "}
                          <CustomLink className={classes.link} href={doc.price.primary_src} target="_blank">
                            Прайс AlphaPet
                          </CustomLink>
                        </Box>
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </>
            )}
            {md && (
              <>
                {submenu.map((i) => {
                  return (
                    <Box py={1} key={i.hash}>
                      <Link
                        className={classNames(classes.submenu, location.hash === i.hash ? "actives" : "s")}
                        component={MyLink}
                        to={`${i.pathname}/${i.hash}`}
                      >
                        {i.name}
                      </Link>
                    </Box>
                  )
                })}
                {doc?.price && (
                  <Box pt={3}>
                    <img src="/imgs/pdf.svg" style={{ marginBottom: -10 }} alt="" />{" "}
                    <CustomLink className={classes.link} href={doc.price.primary_src} target="_blank">
                      Прайс AlphaPet
                    </CustomLink>
                  </Box>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <Case user={data} h={router.location.hash} loadInfo={loadInfo} rules={doc?.rules} />
          </Grid>
        </Grid>
      </Container>
    </PageContainer>
  )
}

export default Page
