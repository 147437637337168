import { Dispatch } from "redux"
import { FETCH_AUTH_REQUEST, FETCH_AUTH_SUCCESS } from "./types"
import { auth as authService } from "../../api/actions"
import { ApiAnswer, ApiAnswerStatus } from "../../api/types"

const requested = () => {
  return {
    type: FETCH_AUTH_REQUEST,
    payload: {}
  }
}

const loaded = (newData: ApiAnswer) => {
  return {
    type: FETCH_AUTH_SUCCESS,
    payload: newData
  }
}

export default (onSuccess = (_d: ApiAnswer) => {}, onError = () => {}) =>
  async (dispatch: Dispatch) => {
    dispatch(requested())

    return authService()
      .then((data: ApiAnswer) => {
        dispatch(loaded(data))
        if (data.status === ApiAnswerStatus.SUCCESS) {
          onSuccess(data)
        } else if (data.status === ApiAnswerStatus.NEED_FULL_REGISTER) {
          onSuccess(data)
        } else if (data.status === ApiAnswerStatus.NEED_CONFIRM) {
          onSuccess(data)
        } else {
          onError()
        }
        return data
      })
      .catch((_e) => {
        onError()
      })
  }
