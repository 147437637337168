export default {
  type: "light",

  common: {
    black: "#0A0E12",
    white: "#fff"
  },

  background: {
    paper: "#EDEDED",
    footer: "#1C232B",
    default: "#e5e5e5",
    backdrop: "rgba(26, 26, 26, .6)",
    hint: "rgba(238,31,37,0.1)",
    contrast: "#fff"
  },

  table: {
    header: "#3e3f41",
    row: "#e8e8e8"
  },

  primary: {
    main: "#AD7D66",
    contrastText: "#fff"
  },
  secondary: {
    main: "#AD7D66",
    contrastText: "#fff"
  },

  error: {
    main: "#AC2C2C",
    contrast: "#fff"
  },
  warning: {
    main: "#f44f00",
    contrast: "#fff"
  },
  info: {
    main: "#5154f4",
    contrast: "#fff"
  },
  success: {
    main: "#58d131",
    contrast: "#fff"
  },
  // ---------------------------- Text
  text: {
    primary: "rgb(171, 134, 38)",
    secondary: "#333333",
    disabled: "rgba(177, 177, 177, 0.38)",
    hint: "rgba(0, 0, 0, 0.38)",
    icon: "rgba(105,105,105,0.54)"
  },
  // ---------------------------- Action
  action: {
    active: "#fff",
    hover: "rgba(145,145,145,0.52)",
    hoverOpacity: 0.04,
    selected: "#fff",
    selectedOpacity: 0.08,
    disabled: "#A6A6A6",
    disabledBackground: "#7b7b7b",
    disabledOpacity: 0.38,
    focus: "#bfbfbf",
    focusOpacity: 0.12,
    activatedOpacity: 0.12
  },
  // ---------------------------- Divider
  divider: "rgba(255, 255, 255, 0.12)",

  tonalOffset: 0.2,
  contrastThreshold: 5
  // getcontrast: () => {},
  // augmentColor: () => {},
}
