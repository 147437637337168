import { TextField } from "@material-ui/core"

type Props = {
  register: any
  helperText: any
  label: string
  placeholder?: string
  type?: "text" | "password" | "date" | "file" | "number" | "tel"
  variant?: "standard" | "filled" | undefined
  color?: "primary" | "secondary"
  fullWidth?: boolean
  InputProps?: any
  className?: any
  onChange?: any
  onKeyDown?: any
  defaultValue?: any
  InputLabelProps?: any
  disabled?: boolean
  select?: boolean
  min?: string
  max?: string
}

const GenericTextInput: React.FC<Props> = (props): JSX.Element => {
  const {
    type = "text",
    disabled = false,
    fullWidth = true,
    variant = "filled",
    color = "primary",
    select = false,
    register,
    children,

    helperText
  } = props

  return (
    <TextField
      {...register}
      InputLabelProps={{
        shrink: true
      }}
      fullWidth={fullWidth}
      type={type}
      variant={variant}
      color={color}
      disabled={disabled}
      select={select}
      {...props}
      helperText={helperText}
    >
      {children}
    </TextField>
  )
}

export default GenericTextInput
