import { createMuiTheme } from "@material-ui/core"
import createBreakpoints from "@material-ui/core/styles/createBreakpoints"
import _font from "./font"
import _palette from "./palette"

const breakpoints = createBreakpoints({})
const defaultTheme = createMuiTheme()

const theme = createMuiTheme({
  breakpoints,

  palette: {
    text: {
      primary: "#fff",
      secondary: "#323E48"
    },

    primary: {
      main: "#AD7D66",
      contrastText: "#fff"
    },

    secondary: {
      main: "#AD7D66",
      contrastText: "#fff"
    }
  },
  typography: {
    fontSize: 17,
    fontFamily: ["HeroNew"].join(","),

    h1: {
      fontWeight: 900,
      fontSize: "294%",
      textTransform: "uppercase"
    },
    h2: {
      color: "#323E48",
      marginTop: 0,
      paddingTop: 10,
      fontWeight: 900,
      fontSize: "270%",
      [breakpoints.down("md")]: {
        fontSize: "4vw!important",
        textTransform: ""
      }
    },
    h3: {
      fontWeight: 600,
      fontSize: "188%",
      [breakpoints.down("md")]: {
        fontSize: 18,
        textTransform: "",
        textAlign: "center"
      }
    },
    h4: {
      fontWeight: 900,
      fontSize: "185%",
      textTransform: "uppercase",
      [breakpoints.down("lg")]: {
        fontSize: "155%"
      },
      [breakpoints.down("xs")]: {
        fontSize: 16
      }
    },
    h5: {
      fontWeight: 400,
      fontSize: "140%",
      [breakpoints.down("md")]: {
        fontSize: 15
      }
    },
    body1: {
      [breakpoints.down("md")]: {
        fontSize: 14,
        textTransform: "",
        textAlign: "center"
      }
    }
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          WebkitFontSmoothing: "auto"
        }
      }
    },
    MuiIconButton: {
      root: {
        lineHeight: 1,
        "&.popoverButton": {
          color: _palette.text.secondary,
          border: "1px solid #fff",
          borderRadius: defaultTheme.spacing(2)
        }
      },
      label: {
        fontSize: "100%"
      },
      colorSecondary: {
        color: `${_palette.text.secondary}`,

        "&.popoverButton": {
          backgroundColor: "#cf0a2c",
          border: "0 none"
        }
      }
    },
    MuiBackdrop: {
      root: {
        // backdropFilter: 'blur(2px)',
      }
    },
    MuiPickersDay: {
      daySelected: {
        "& span": {
          color: "#fff"
        }
      }
    },

    MuiAccordion: {
      root: {
        borderRadius: 0,
        boxShadow: "none",
        margin: "0!important"
      }
    },
    MuiAccordionSummary: {
      root: {
        padding: 0,
        borderRadius: 0,
        borderBottom: "1px solid #E3DDDC",
        minHeight: "48px!important",
        "&$expanded": {
          margin: 0
        }
      },
      content: {
        margin: "0!important"
      }
    },
    MuiDialog: {
      paper: {
        width: "100%",
        backgroundColor: "#fff",
        borderRadius: "0px",
        color: "#323E48",
        boxSizing: "border-box",
        boxShadow: "inset 0 0 15px rgba(0,0,0,.1)",
        overflowY: "visible!important"
      },
      paperFullScreen: {
        height: "auto",
        maxHeight: "auto"
      },
      paperWidthSm: {
        // maxWidth: 550
      }
    },
    MuiDialogContent: {
      root: {
        paddingLeft: defaultTheme.spacing(2),
        paddingRight: defaultTheme.spacing(2),
        paddingBottom: 0,
        textAlign: "center",
        fontWeight: 400,
        fontSize: 18,
        overflowY: "visible",

        [breakpoints.down("sm")]: {
          paddingLeft: defaultTheme.spacing(2),
          paddingRight: defaultTheme.spacing(2),
          paddingBottom: 0,
          fontSize: 14
        },

        "& .text": {
          color: "#323E48"
        },

        "& .link": {
          fontSize: "100%",
          color: "#323E48",
          textDecoration: "none",
          "&:hover": {
            color: "#323E48"
          }
        },
        "&>*:last-child": {
          marginBottom: defaultTheme.spacing(4)
        },
        "& .fields": {
          textAlign: "center",
          color: _palette.text.secondary,
          backgroundColor: "transparent",
          paddingTop: defaultTheme.spacing(2),
          "& fieldset": {
            // borderColor: '#f4d48c!important',
          },

          [breakpoints.down("sm")]: {},
          [breakpoints.down("xs")]: {
            // paddingTop: defaultTheme.spacing(4),
          },

          "& .field_row>*": {
            [breakpoints.up("md")]: {
              "&:first-child:not(:last-child)": {
                paddingRight: defaultTheme.spacing(1)
              },
              "&:last-child:not(:first-child)": {
                paddingLeft: defaultTheme.spacing(1)
              }
            }
          },
          "&.fields-bottom": {
            // костыль
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0
          },
          "&.fields-top:first-child:not(:last-child)": {
            // костыль
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            marginBottom: -1
          }
        },
        "& .actions": {
          // width: "75%",
          marginLeft: "auto",
          marginRight: "auto"
        }
      }
    },

    MuiDropzoneArea: {
      root: {
        minHeight: 100,
        border: "dotted",
        borderColor: "#AD7D66",
        borderWidth: "1px",
        borderRadius: 0
      },
      textContainer: {
        fontSize: 12,
        maxWidth: 250,
        marginLeft: "auto",
        marginRight: "auto",
        "& p": {
          marginBottom: 5
        }
      },
      icon: {
        color: "#AD7D66"
      },
      removeButton: {}
    },
    MuiDropzoneSnackbar: {
      infoAlert: {},
      successAlert: {},
      warningAlert: {},
      errorAlert: {},
      message: {},
      icon: {},
      closeButton: {}
    },
    MuiDropzonePreviewList: {
      imageContainer: {
        padding: 20,
        "& p": {
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: 14,
          lineHeight: 1
        }
      },
      image: {
        height: 75,
        boxShadow: "none",
        borderRadius: 0
      }
    },
    MuiDialogTitle: {
      root: {
        position: "relative",
        backgroundColor: "transparent",
        color: "#323E48",
        textAlign: "center",
        fontSize: 30,
        textTransform: "none",
        fontWeight: 900,
        display: "flex",
        paddingTop: 50,
        justifyContent: "center",

        [breakpoints.down("xs")]: {
          fontSize: 22,
          paddingTop: 30,
          maxWidth: "90%"
        }
      }
    },
    MuiContainer: {
      root: {
        maxWidth: 1680,
        margin: "0 auto"
      }
    },
    MuiPickersBasePicker: {
      pickerView: {
        margin: "0 auto"
      }
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        "& h2": {
          margin: 0,
          padding: "0 1rem"
        }
      }
    },

    MuiButton: {
      root: {
        borderRadius: 0,
        zIndex: 1,
        textTransform: "uppercase",
        fontWeight: 400,
        "&:hover": {
          borderColor: "#fff",
          color: "#fff",
          textShadow: "none"
        },
        "&:active,&.active": {},
        "&$disabled": {
          // opacity: '.9',
        }
      },

      outlinedPrimary: {
        borderRadius: 0,
        paddingLeft: 30,
        paddingRight: 30,
        color: "#AD7D66",
        height: 50,
        fontWeight: 600,
        border: "1px solid #AD7D66",

        "&:hover": {
          border: "1px solid #8E583E!important",
          background: "#8E583E!important",
          color: "#fff"
        },
        "&$disabled": {
          border: "1px solid rgba(173, 125, 102, 0.60)"
        },
        [breakpoints.down("md")]: {
          height: 41,
          fontSize: 14
        }
      },
      contained: {
        paddingLeft: 30,
        paddingRight: 30,
        color: "#8C9297",
        fontSize: 14,
        height: 48,
        fontWeight: 600,
        backgroundColor: "#fff",
        boxShadow: "none",
        "&:hover": {
          border: "0px solid #fff",
          color: "#AD7D66",
          background: "#fff!important",
          boxShadow: "none"
        }
      },
      containedPrimary: {
        paddingLeft: 30,
        paddingRight: 0,
        color: "#fff",
        fontSize: 14,
        height: 64,
        fontWeight: 600,
        border: "0px solid #AD7D66",
        background: "#AD7D66",

        "&:after": {
          display: "block",
          height: 64,
          marginLeft: 20,
          width: 64,
          borderLeft: "2px solid #D6BEB2",
          background: 'url("/imgs/arrow.svg") no-repeat center transparent',
          color: "#D6BEB2",
          content: '""',
          paddingLeft: 20
        },

        "&:hover": {
          border: "0px solid #AD7D66",
          color: "#fff",
          background: "#8E583E!important",
          "&:after": {
            background: 'url("/imgs/arrow.svg") no-repeat center transparent'
          }
        },

        "&$disabled": {
          opacity: ".8",
          color: "#fff",
          background: "#E1BD8C"
        },

        [breakpoints.down("md")]: {
          height: "auto",
          paddingTop: 0,
          paddingBottom: 0,
          fontSize: 12,
          "&:after": {
            height: 48
          }
        }
      },
      textPrimary: {
        "&:hover": {
          color: "#D6BEB2"
        }
      },
      label: {
        lineHeight: 1
      },
      containedSecondary: {
        paddingLeft: 30,
        paddingRight: 30,
        color: "#fff",
        fontSize: 14,
        height: 48,
        fontWeight: 600,
        border: "0px solid #AD7D66",
        background: "#AD7D66",

        "&:hover": {
          border: "0px solid #AD7D66",
          color: "#fff",
          background: "#8E583E!important"
        },

        "&$disabled": {
          opacity: ".8",
          color: "#fff",
          background: "#E1BD8C"
        }
      },
      sizeSmall: {
        textTransform: "none",
        fontWeight: 400,
        height: 35,
        fontSize: 14
      }
    },
    MuiAppBar: {
      root: {
        width: "auto"
      },
      colorPrimary: {
        backgroundColor: "transparent"
      }
    },
    MuiSelect: {
      iconFilled: {
        fontSize: "1.5rem",
        color: "#000"
      },
      selectMenu: {
        textAlign: "left"
      },
      select: {
        "&:focus": {
          backgroundColor: "transparent"
        }
      }
    },
    MuiMenuItem: {
      root: {
        "&$selected": {
          // background: _palette.secondary,
        }
      },
      gutters: {
        color: _palette.primary
      }
    },
    MuiSvgIcon: {
      root: {
        fontSize: "100%"
      }
    },
    MuiDrawer: {
      paper: {
        backgroundColor: "#fff",
        maxWidth: 400
      }
    },
    MuiTableBody: {
      root: {
        paddingTop: 22,
        textTransform: "none",

        paddingBottom: 22,
        minHeight: 70,
        "& a": {
          color: "#323E48"
        },

        "&>tr:nth-child(even)": {
          "&>td": {
            backgroundColor: "#F8F6F5",
            color: "#323E48"
          }
        }
      }
    },
    MuiTableCell: {
      root: {
        border: "none!important",
        textAlign: "center",
        fontSize: "100%",
        fontWeight: 400,
        lineHeight: 1.25,
        minHeight: 70,
        paddingBottom: 18,
        paddingTop: 18
      },
      body: {
        color: "#323E48",
        fontSize: 16,
        fontWeight: 400,
        "& .m_cell": {
          fontSize: 14,
          fontWeight: 400,
          "& button": { paddingLeft: 0, "& span": { justifyContent: "flex-start" } }
          // "& .ball": { justifyContent: "flex-start !important" }
        },
        "& .m_cell_title": {
          color: _palette.primary.main,
          fontSize: 13,
          fontWeight: 500,
          paddingBottom: defaultTheme.spacing(1 / 3),
          [breakpoints.down("xs")]: { fontSize: 10 }
        }
      }
    },
    MuiTableRow: {},
    MuiTable: {
      root: {
        width: "100%"
      }
    },

    MuiTableHead: {
      root: {
        padding: 0,

        "& .MuiTableCell-root": {
          textAlign: "center",
          backgroundColor: "transparent",
          color: "#AD7D66",
          padding: "22px 10px",

          fontWeight: 600,
          textTransform: "uppercase",
          lineHeight: 1,
          fontSize: 14,
          minHeight: 50,
          borderBottom: "2px solid #AD7D66!important"
        }
      }
    },

    /* форма */
    MuiCheckbox: {
      root: {
        padding: defaultTheme.spacing(1 / 2)
      },
      colorPrimary: {
        color: _palette.text.secondary,
        "&$checked": {
          color: `${_palette.text.secondary}`
        },
        "&$disabled": {
          color: "rgba(255, 255, 255, 0.5)"
        }
      },
      colorSecondary: {
        marginTop: -5,
        "&$checked": {
          color: `${_palette.secondary.main}`
        },
        "&$disabled": {
          color: "rgba(255, 255, 255, 0.5)"
        }
      }
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: "#FAFAFA",
        color: _palette.text?.primary,
        flexWrap: "nowrap",
        fontSize: 12,
        borderRadius: 0,
        alignItems: "center",
        lineHeight: 1.25,
        textAlign: "center",
        boxShadow: "0 0 1em rgba(0,0,0,0.1)",
        "& p": { margin: 0 }
      },
      message: {
        flex: "0 0 100%",
        padding: 0,
        fontWeight: 400
      },
      action: {}
    },
    MuiFormHelperText: {
      root: {
        left: 0,
        bottom: 18,
        position: "absolute",
        textOverflow: "ellipsis",
        overflow: "hidden",
        width: "100%",
        whiteSpace: "nowrap",
        lineHeight: 1,
        fontSize: 12,
        color: `${_palette.error.main}!important`,

        "&$error": {
          color: _palette.error.main
        },

        "&.checkbox-help": {
          bottom: 0,
          position: "relative"
        }
      },
      contained: {
        marginLeft: 0,
        marginRight: 0
      }
    },
    MuiFormControl: {
      root: {
        paddingBottom: 15,
        marginTop: 0,
        marginBottom: 0,
        maxWidth: "100%",
        "& label": {
          fontWeight: 400
        }
      }
    },
    MuiPagination: {
      root: { display: "inline-block" }
    },

    MuiPaginationItem: {
      root: {
        fontSize: 14,
        fontWeight: 500,
        minWidth: 25,
        width: 25,
        height: 25,
        color: "#323E48",
        borderRadius: "50%",
        "&$selected": {
          color: "#AD7D66",
          borderRadius: "50%",
          textDecoration: "none",
          background: `transparent!important`,
          "&$textSecondary": {
            color: _palette.text.secondary
            // backgroundColor: '#fff',
          },
          "&$textPrimary": {
            color: _palette.text.secondary
            // backgroundColor: '#fff',
          }
        },
        "&$disabled": {
          // visibility: 'hidden',
        },
        "&$textSecondary": {
          color: "#fff"
        },
        "&:hover": {
          transaction: "none"
          // backgroundColor: 'transparent!important',
        }
      },
      outlined: {
        // backgroundColor: '#ffffff',
        border: "none",
        color: _palette.primary,
        fontSize: "133%",
        height: 40,
        width: 40,
        borderRadius: 10,
        [breakpoints.down("xs")]: {
          height: 29,
          width: 29
        },
        "&$sizeSmall": {
          color: "#fff!important"
        },
        "&$disabled": {
          visibility: "visibility"
        },
        "&$selected": {
          color: "#fff",
          textDecoration: "none!important",
          backgroundColor: _palette.primary,
          borderRadius: 10,
          "&$textSecondary": {
            color: _palette.secondary
          },
          "&$sizeSmall": {
            background: "#fff!important",
            color: `${_palette.primary}!important`
          }
        }
      },
      ellipsis: {
        color: "initial"
      },
      icon: {
        fontSize: "150%"
      }
    },
    MuiOutlinedInput: {
      root: {
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderColor: "#fff",
        borderWidth: 2,
        fontSize: "80%",
        color: "#fff",

        padding: 0,
        height: 40,
        maxHeight: 40,
        "&:hover $notchedOutline": {
          borderColor: "#fff",
          borderWidth: 2
        },
        "&$focused $notchedOutline": {
          borderColor: "#fff",
          borderWidth: 2
        },
        "&$error $notchedOutline": {
          borderColor: "#fff",
          borderWidth: 2
        },
        "&$disabled $notchedOutline": {
          borderWidth: 2
        }
      },
      multiline: {
        height: "auto",
        minHeight: 60,
        maxHeight: "none"
      },
      notchedOutline: {
        borderRadius: 5,
        borderColor: "#fff",
        borderWidth: 1,
        top: 0,
        "& legend": {
          display: "none"
        }
      },
      input: {
        padding: "5px 15px",
        height: "auto",
        lineHeight: 1,
        "&$disabled": {
          color: "rgba(255, 255, 255, 0.5)"
        },
        "&:-internal-autofill-selected": {
          background: "transparent",
          "-webkit-box-shadow": "inset 0 0 0 50px #F4F4F4!important",
          "-webkit-text-fill-color": "#fff!important"
        },
        "&:-webkit-autofill": {
          background: "transparent",
          "-webkit-box-shadow": "inset 0 0 0 50px #F4F4F4!important",
          "-webkit-text-fill-color": "#fff!important"
        }
      },
      adornedEnd: {
        paddingRight: 0
      }
    },
    MuiFilledInput: {
      root: {
        fontSize: "16px!important",
        fontWeight: 400,
        minHeight: 48,
        color: "#323E48",
        border: `1px solid #DAD0CB`,
        marginBottom: 17,
        borderRadius: "0px!important",
        background: "#F4F4F4!important",
        "&:before, &:after": {
          content: "none!important"
        },
        "&:hover": {
          backgroundColor: "#fff"
        },
        "&$error": {
          color: "#323E48!important",
          fontWeight: 600,
          borderColor: "#DAD0CB"
        },
        "&$focused": {
          backgroundColor: "#fff"
        }
      },
      input: {
        fontWeight: 400,

        padding: "0 10px",

        "&:-internal-autofill-selected": {
          background: "transparent",
          "-webkit-box-shadow": "inset 0 0 0 50px #F4F4F4!important",
          "-webkit-text-fill-color": "#000!important"
        },
        "&:-webkit-autofill": {
          background: "transparent",
          "-webkit-box-shadow": "inset 0 0 0 50px #F4F4F4!important",
          "-webkit-text-fill-color": "#000!important"
        }
      }
    },
    MuiInputBase: {
      root: {
        borderColor: "#fff!impoetant"
      },
      input: {
        appearance: "none",
        height: "auto",
        lineHeight: 1.2
      }

      // input: {
      //   '&:-internal-autofill-selected': {
      //     background: 'transparent',
      //     '-webkit-box-shadow': 'inset 0 0 0 50px #fff!important',
      //     '-webkit-text-fill-color': '#001645!important',
      //   },
      //   '&:-webkit-autofill': {
      //     background: 'transparent',
      //     '-webkit-box-shadow': 'inset 0 0 0 50px #fff!important',
      //     '-webkit-text-fill-color': '#001645!important',
      //   },
      // },
    },
    MuiExpansionPanelSummary: {
      root: {
        paddingTop: 0,
        paddingBottom: 0,
        fontWeight: 600,
        fontSize: 20,
        paddingLeft: 20,
        color: "#fff",
        margin: 0,
        [breakpoints.down("sm")]: {
          fontSize: "16px"
        },
        "&$expanded": {
          color: "#fff",
          margin: 0,

          minHeight: "none",
          borderColor: "#ffffff"
        }
      },
      content: {
        marginTop: 0,
        marginBottom: 0,
        "&$expanded": {
          marginTop: 0,
          marginBottom: 0
        }
      },
      expandIcon: {
        marginTop: 10,
        marginBottom: 10,
        color: "#ffffff",
        fontSize: 20,
        background: "#7B8AAD",
        padding: 5,
        order: 0,
        "&:hover": {
          backgroundColor: "#7B8AAD"
        }
      }
    },
    MuiExpansionPanel: {
      root: {
        background: "transparent",
        border: "1px solid #40517A",
        marginBottom: defaultTheme.spacing(2),
        "&:last-child": {
          borderBottom: "none"
        },
        margin: 0,

        "&:hover": {
          border: "1px solid transparent",
          background: "#102659"
        },
        "&$expanded": {
          border: "1px solid transparent",
          background: "#102659",
          margin: 0,
          marginBottom: 10
        }
      },
      rounded: {
        borderRadius: 10,
        "&:first-child, &:last-child": {
          borderRadius: 10
        }
      }
    },
    MuiExpansionPanelDetails: {
      root: {
        fontSize: "18px",
        lineHeight: 1.3,
        fontWeight: 400,
        color: "#fff",
        display: "block",
        padding: "8px 20px 10px",
        marginBottom: defaultTheme.spacing(1),
        [breakpoints.down("sm")]: {
          fontSize: "16px"
        },
        "& a": {
          // color: _palette.secondary + '!important',
        }
      }
    },
    MuiInputLabel: {
      root: {
        transformOrigin: "left center",
        zIndex: 2,
        "&$focused": {
          color: _palette.primary,
          transform: "translate(0px, -20px) scale(.8)"
        },
        fontWeight: 400,
        paddingLeft: 0,
        paddingRight: 0,
        "&$error": {
          color: "red"
        }
      },
      formControl: {
        transform: "translate(0px, 17px) scale(1)"
      },

      filled: {
        fontWeight: 400,
        fontSize: "16px",
        transform: "translate(12px, 17px)",
        "&$shrink": {
          fontSize: "14px",
          transform: "translate(0px, -125%)",
          color: "#323E48"
        }
      }
    },
    MuiFormLabel: {
      root: {
        "&$disabled": {
          color: "rgba(255, 255, 255, 0.5)"
        },
        "&focused": {
          color: "#fff"
        }
      }
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
        alignItems: "flex-start",
        "& a": {
          color: "inherit",
          textDecoration: "underline"
        }
      },
      label: {
        fontSize: 14,
        textAlign: "left",
        paddingLeft: 10,
        lineHeight: 1.2,
        "&$disabled": {
          color: "rgba(255, 255, 255, 0.5)"
        }
      }
    },
    MuiAutocomplete: {
      inputRoot: {
        paddingTop: "0!important"
      },
      filled: {
        paddingTop: 0
      },
      input: {},
      endAdornment: {
        color: "#323E48",
        top: 0,
        transform: "translate(0, 25%)"
      },
      clearIndicator: {
        color: "#AD7D66"
      },
      popupIndicator: {
        color: "#323E48"
      },
      noOptions: {
        display: "none"
      },
      option: {
        paddingTop: 5,
        paddingBottom: 5,
        fontSize: 14,
        textAlign: "left"
      }
    },
    MuiDialogActions: {
      root: {
        paddingBottom: 20,
        justifyContent: "center",
        "& button": {
          fontSize: 14,
          padding: "10px 15px",
          color: "#fff",
          background: "#AD7D66",
          fontWeight: 400,
          "&:hover": {
            background: "#AD7D66"
          }
        }
      }
    },
    MuiPickersModal: {
      dialogRoot: {
        background: "#fff",
        width: "auto",
        borderRadius: 0
      },
      dialog: {
        fontSize: 21,
        color: "#000"
      }
    },
    MuiPopover: {
      paper: {
        maxWidth: 300,
        backgroundColor: "#fff",
        color: "#000",
        fontSize: "85%",
        textTransform: "none",
        fontWeight: 400,
        width: "100%"
      }
    },
    MuiTypography: {
      colorPrimary: {
        color: "#fff"
      },
      colorSecondary: {
        color: "#323E48"
      },
      h2: {
        textAlign: "center",
        color: "#fff",
        textTransform: "uppercase",
        marginTop: defaultTheme.spacing(12),
        marginBottom: defaultTheme.spacing(4),
        fontSize: 32,
        fontWeight: 700,
        [breakpoints.down("sm")]: {
          fontSize: 24
        }
      },
      subtitle2: {
        textTransform: "uppercase",
        fontWeight: 600,
        fontSize: 18,
        marginBottom: 10
      }
    },
    MuiPaper: {
      outlined: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%",
        backgroundColor: "#102659",
        boxShadow: "none",
        borderRadius: 10,
        padding: defaultTheme.spacing(2),
        color: "#fff",
        fontWeight: 400,
        textAlign: "center",
        [breakpoints.down("sm")]: {
          height: "auto"
        },
        "& span": {
          fontSize: 16
        },
        "& a": {
          cursor: "pointer",
          fontSize: 13,
          textTransform: "uppercase",
          color: "#fff",
          display: "block",

          padding: 15,
          border: "2px solid rgba(255,255,255,0.3)",
          borderRadius: 8,
          textDecoration: "none",
          textAlign: "center"
        },
        "& h1, & h2": {
          marginTop: defaultTheme.spacing(1),
          textTransform: "uppercase",
          color: "#fff",
          fontWeight: 600
        },
        "& h1": { fontSize: 50, marginBottom: 0 },
        "& h2": {}
      },
      elevation4: {
        boxShadow: "none"
      }
    },
    MuiTab: {
      root: { boxShadow: "none" },
      wrapper: {
        padding: "0 10px",
        textTransform: "uppercase",
        fontWeight: 600,
        fontSize: 32,

        color: "#323E48",
        [breakpoints.down("sm")]: {
          fontSize: 28,
          padding: "0 0.4em"
        }
      },

      textColorInherit: {
        borderRadius: 20,
        opacity: 0.5,
        "&[aria-selected^='true']": {
          background: "rgba(255,255,255,0.1)"
        }
      }
    },
    PrivateTabIndicator: {
      root: {
        background: `none`
      },
      colorSecondary: { backgroundColor: "transparent" }
    }
  },
  font: _font
})

export default theme
