type PropsEvent = {
  eventError?: string
  event?: string
  eventCategory: string
  eventAction: string
  eventLabel?: string
  eventContext?: string
}

const TrackEvent = ({ eventCategory, eventAction, eventLabel, eventContext, eventError }: PropsEvent) => {
  const p: PropsEvent = {
    event: "GAEvent",
    eventCategory,
    eventAction
  }

  Object.assign(p, {
    eventLabel,
    eventContext,
    eventError
  })
  window?.dataLayer?.push(p)
}

export default TrackEvent
