import { makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles(({ breakpoints: { down } }: Theme) => ({
  tabPanel: {
    "& > div": {
      padding: 0
    }
  },

  list: {
    [down("xs")]: { width: "calc(100% + 32px)", marginLeft: -16 }
  },

  box1: {
    padding: 10,
    textAlign: "center",
    color: "#323E48",
    background: "#D2E1E6",
    height: "100%",
    "& .label": {
      fontSize: 14,
      textTransform: "uppercase",
      fontWeight: 600,
      [down("md")]: { fontSize: 13 }
    },
    "& .ball": {
      fontSize: 40,
      textTransform: "uppercase",
      fontWeight: 700,
      [down("lg")]: { fontSize: 25 },
      [down("md")]: { fontSize: 20 }
    }
  },
  box2: {
    textAlign: "center",
    color: "#323E48",
    background: "#E9F0F2",
    height: "100%",
    "& .label": {
      paddingTop: 17,
      paddingBottom: 5,
      fontSize: 14,
      textTransform: "uppercase",
      fontWeight: 600
    },
    "& .ball": {
      fontSize: 24,
      textTransform: "uppercase",
      fontWeight: 700,
      paddingBottom: 17
    }
  }
}))

export default useStyles
