const Phone: React.FC<{ children: string }> = ({ children }) => {
  if (!children) return <></>
  const v = children?.replace(/\D.*/gi, "")
  return (
    <>
      +7&nbsp;{v.substring(0, 3)}&nbsp;{v.substring(3, 6)}&nbsp;{v.substring(6, 10)}
    </>
  )
}

export default Phone
