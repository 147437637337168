import { Box, Button } from "@material-ui/core"
import Dialog from "../../components/dialog"
import russianRules from "../../utils/russianRules"

export type Msg = {
  status?: number
  title?: string
  message?: any
  btnText?: string | undefined
  text?: string | undefined
  onClose?: any
}

const GlobalAlertModal: React.FC<{ msg?: Msg | undefined }> = ({ msg }) => {
  const { title = "Информация", message, text, btnText = "ОК", onClose = () => {} } = msg || {}

  return (
    <Dialog title={title} maxWidth="sm" onOpen={() => {}} onCloseDialog={onClose} name="alert-modal" open={!!message}>
      <Box>
        {text && <Box>{russianRules(text)}</Box>}

        {!!message &&
          !text &&
          Object.keys(message).map((key, index) => {
            const value = message[key]

            return (
              <Box key={`message-${index}`}>
                {Array.isArray(value)
                  ? value.map((v: any, i: number) => (
                      <span key={i}>
                        {russianRules(v)}
                        <br />
                      </span>
                    ))
                  : Object.keys(value).map((kv, ind) => {
                      return <Box key={ind}>{russianRules(value[kv])}</Box>
                    })}
              </Box>
            )
          })}
      </Box>
      <Box pt={4}>
        <Button fullWidth={false} type="submit" variant="contained" color="primary" onClick={() => onClose()}>
          {btnText}
        </Button>
      </Box>
    </Dialog>
  )
}

export default GlobalAlertModal
