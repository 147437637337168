import { makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles(({ breakpoints: { down } }: Theme) => ({
  link: {
    textDecoration: "none",
    borderBottom: "1px dashed #8C9297",
    color: "#8C9297",
    fontSize: 15
  },
  root: {
    padding: "40px 15px",
    background: "#F0EDEC",
    textAlign: "center",
    [down("xs")]: { padding: "40px 0" }
  },
  mFullWidth: {
    [down("xs")]: { width: "calc(100% + 32px)", marginLeft: -16 }
  }
}))

export default useStyles
