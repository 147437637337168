import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import Dialog from "../../../../components/dialog"
import { useSelector } from "../../../../hooks"
import Form from "./form"
import { SET_PROPS } from "../../../../store/props/types"
import { GlobalAlertModal } from "../../../../modal/globalAlert"
import { Msg } from "../../../../modal/globalAlert/alert"

export const modalName = "ExhibitionModalName"

export const ExhibitionModal: React.FC<{ onClose: any }> = ({ onClose }) => {
  const dispatch = useDispatch()
  const { modal } = useSelector((state) => state.propsReducer)
  const { show = false } = modal[modalName] || {}
  const [msg, setMsg] = useState<Msg>()

  const onCloses = () => {
    onClose()
    dispatch({
      type: SET_PROPS,
      payload: {
        modal: {
          [modalName]: { show: false }
        }
      }
    })
  }

  useEffect(() => {
    if (msg && msg.status === 1) {
      onCloses()
    }
  }, [msg])

  return (
    <>
      <GlobalAlertModal msg={msg} />
      <Dialog
        title="Добавить выставку"
        maxWidth="sm"
        onOpen={() => {}}
        onCloseDialog={onCloses}
        name={modalName}
        open={show}
      >
        <Form onClose={onCloses} setMsg={setMsg} />
      </Dialog>
    </>
  )
}
