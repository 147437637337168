export const SET_PROPS = "SET_PROPS"
export const HIDE_MODAL = "HIDE_MODAL"
export interface DialogProps {
  [name: string]: { open?: boolean }
}

export interface File {
  key: string
  primary_src: string
  title: string
}

export interface ProjectProps {
  isAuth: boolean
  init: boolean
  load?: boolean
  userStatus?: string | null
  dialog?: DialogProps
  recaptchaSitekey?: string
  modal: any
  doc?: {
    promotion_rules: File
    mechanics: File
    rules: File
    agreement: File
    owner_profile: File
    price: File
  }
}

interface SetProps {
  type: typeof SET_PROPS
  payload: ProjectProps
}

export type PROPS = SetProps
