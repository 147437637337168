import { Button, ButtonProps, makeStyles } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"
// eslint-disable-next-line import/no-extraneous-dependencies
import classNames from "classnames"

const useStyles = makeStyles((theme) => ({
  actionSubmit: {},
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    color: "#fff",
    marginTop: theme.spacing(-3 / 2),
    marginLeft: theme.spacing(-3 / 2)
  }
}))

type ButtonPropsCustom = ButtonProps & { processed?: boolean }

const SubmitButton: React.FC<ButtonPropsCustom> = ({
  className,
  onClick = () => {},
  processed = false,
  disabled = false,
  title,
  type = "submit",
  variant = "contained",
  color = "primary",
  ...props
}) => {
  const classes = useStyles()
  return (
    <Button
      className={classNames(classes.actionSubmit, className)}
      onClick={onClick}
      type={type}
      variant={variant}
      color={color}
      disabled={disabled || processed}
      {...props}
    >
      {title || props.children}
      {processed && <CircularProgress size={24} className={classes.buttonProgress} />}
    </Button>
  )
}

export default SubmitButton
