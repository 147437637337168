import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Box } from "@material-ui/core"
import { useDispatch } from "react-redux"
import { signout } from "../../api/actions"
import PageContainer from "../layout/page-container"
import { SET_PROPS } from "../../store/props/types"
import AuthAction from "../../store/auth/action"

const SignoutPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    signout().then(() => {
      history.push("/", { forceRefresh: true })
      dispatch({
        type: SET_PROPS,
        payload: { isAuth: false, userStatus: null }
      })
      dispatch(AuthAction(() => {}))
    })
  }, [])

  return (
    <PageContainer>
      <Box textAlign="center" mb={4} fontSize="75%">
        Завершение работы...
      </Box>
    </PageContainer>
  )
}

export default SignoutPage
