import { Box, Grid } from "@material-ui/core"

const PageContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Grid item xs={12} className="animated fadeIn faster" style={{ height: "100%" }}>
      <Box pt={{ xs: 8, md: 15 }} style={{ minHeight: "100%" }}>
        {children}
      </Box>
    </Grid>
  )
}

export default PageContainer
