import useReactRouter from "use-react-router"
import { useEffect, useState } from "react"
import Dialog from "../../components/dialog"
import TrackEvent from "../../components/GAnalitics"
import { useSelector } from "../../hooks"
import SigninForm from "./form"
import { GlobalAlertModal } from "../globalAlert"
import { Msg } from "../globalAlert/alert"

export const modalName = "SigninModal"

const SignInModal = () => {
  const { history } = useReactRouter()
  const { modal, isAuth } = useSelector((state) => state.propsReducer)
  const { show = false } = modal[modalName] || {}
  const [msg, setMsg] = useState<Msg>()

  const onClose = () => {
    if (isAuth) {
      history.push("/profile")
    } else {
      history.push({ search: "" })
    }
  }

  useEffect(() => {
    if (msg && msg.status === 1) {
      onClose()
    }
  }, [msg])

  return (
    <>
      <GlobalAlertModal msg={msg} />
      <Dialog
        title="Авторизация"
        maxWidth="sm"
        onOpen={() => {
          TrackEvent({ eventCategory: "Authorization", eventAction: "open_a_form" })
        }}
        onCloseDialog={onClose}
        name={modalName}
        open={show}
      >
        <SigninForm setMsg={setMsg} />
      </Dialog>
    </>
  )
}

export default SignInModal
