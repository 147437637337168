import { createStore, combineReducers, applyMiddleware } from "redux"
import { reducer as formReducer } from "redux-form"
import thunkMiddleware from "redux-thunk"

import authReducer from "./auth/reducer"
import propsReducer from "./props/reducer"

const reducer = combineReducers({
  form: formReducer,
  propsReducer,
  authReducer
})

const store = createStore(reducer, applyMiddleware(thunkMiddleware))

export type RootState = ReturnType<typeof reducer>

export type AppDispatch = typeof store.dispatch

export default store
