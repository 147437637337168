import { makeStyles } from "@material-ui/core/styles"
import { SnackbarContent, IconButton, Box, Collapse, Grid } from "@material-ui/core"
import ReactHtmlParser from "react-html-parser"
// eslint-disable-next-line import/no-extraneous-dependencies
import classNames from "classnames"
import { useEffect, useState } from "react"
import { CheckCircle, Close, Error, Info, Warning } from "@material-ui/icons"
import _palette from "../../theme/palette"

const useStyles = makeStyles((theme) => ({
  success: {},
  error: {},

  icon: {
    fontSize: 25
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  messages: {
    display: "flex",
    alignItems: "center",
    textAlign: "center"
  },
  answerMessages: {
    "&>*:not(:first-child)": {
      marginTop: theme.spacing(1)
    }
  },
  closeButton: {
    padding: 0,
    color: _palette.text?.primary
  },
  snackbar: {
    background: "#F4F4F4",
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: 0, // theme.spacing(1),
    marginBottom: 0 // theme.spacing(1),
  }
}))

export type SnackbarProp = {
  show: boolean
  onClose: any
  variant?: any
  answerMessage?: any
}

const SnackbarCustom: React.FC<SnackbarProp> = ({
  show = false,
  answerMessage = {},
  variant = "error",
  onClose = () => {},
  ...props
}) => {
  const classes = useStyles()
  const [animClass, setAnimClass] = useState<"zoomIn" | "zoomOut">("zoomIn")
  const [open, setOpen] = useState<boolean>(false)

  const variantIcon: any = {
    success: CheckCircle,
    warning: Warning,
    error: Error,
    info: Info,
    reset: undefined
  }

  const Icon: any = variantIcon[variant]

  const handleClose = () => {
    setAnimClass("zoomOut")
    setOpen(false)
    onClose()
  }
  const messages = Object.keys(answerMessage || {})

  useEffect(() => {
    const timer = setTimeout(() => {
      if (show) {
        setOpen(true)
        setAnimClass("zoomIn")
      } else {
        handleClose()
      }
    }, 150)
    return () => clearTimeout(timer)
  }, [show])

  return show ? (
    <Collapse in={open}>
      <Box className={`animated ${animClass} faster`} pb={2}>
        <SnackbarContent
          className={classNames(classes.snackbar)}
          aria-describedby="client-snackbar"
          message={
            <Box id="client-snackbar" className={classes.messages}>
              <Grid container spacing={2} alignItems="center" justify="space-between" wrap="nowrap" direction="row">
                <Grid item>{Icon && <Icon color="inherit" style={{ fontSize: 24 }} />}</Grid>
                <Grid item>
                  {messages.length > 0 && (
                    <Box>
                      {messages.map((key, index) => {
                        const value: any = answerMessage[key]
                        return (
                          <Box key={`answer-message-err-${index}`}>
                            {Array.isArray(value) ? (
                              value.map((v, i) => (
                                <span key={i}>
                                  {ReactHtmlParser(v)}
                                  <br />
                                </span>
                              ))
                            ) : (
                              <span> {ReactHtmlParser(value)}</span>
                            )}
                          </Box>
                        )
                      })}
                    </Box>
                  )}
                </Grid>

                <Grid item>
                  <IconButton
                    key="close"
                    color="inherit"
                    aria-label="close"
                    onClick={handleClose}
                    style={{ marginRight: -12 }}
                  >
                    <Close style={{ fontSize: 24 }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          }
          {...props}
        />
      </Box>
    </Collapse>
  ) : (
    <></>
  )
}

export default SnackbarCustom
