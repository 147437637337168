import { useEffect, useState } from "react"
import { Box, Table, TableRow, TableCell, TableHead, TableBody, Grid, Hidden } from "@material-ui/core"
import Moment from "react-moment"
import Pagination from "@material-ui/lab/Pagination"
import BallView from "../../components/ball/ball"
import { Ball } from "../../types/user"
import Phone from "../../utils/phone"

const countArray = (ar: any) => (ar ? ar.length : 0)

const BallList: React.FC<{ items: Ball[] }> = ({ items = [] }) => {
  const [page, setPage] = useState<number>(1)
  const perPage = 5

  const [ball, setBall] = useState<Ball[]>([])
  useEffect(() => {
    if (items) setBall([...items].reverse())
  }, [items])

  const handleChange = (_event: any, value: number) => {
    setPage(value)
  }
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <Hidden xsDown>
              <TableCell>Дата НАЧИСЛЕНИЯ</TableCell>
              <TableCell>ТИП ДЕЙСТВИЯ</TableCell>
              <TableCell>Начисленные баллы</TableCell>
            </Hidden>
          </TableRow>
        </TableHead>

        <TableBody>
          {ball.length > 0 ? (
            ball
              .slice(perPage * (page - 1), page * perPage)
              .map(({ id, breeder_balance_category, score, created_at, owner }: Ball) => (
                <TableRow key={`balllist-${id}`}>
                  <Hidden xsDown>
                    <TableCell>
                      <Moment format="DD.MM.YYYY">{created_at}</Moment>
                    </TableCell>

                    <TableCell>
                      {breeder_balance_category.title}{" "}
                      {owner && (
                        <Box>
                          <small>
                            {owner?.last_name} <Phone>{owner?.phone}</Phone>
                          </small>
                        </Box>
                      )}
                    </TableCell>

                    <TableCell>
                      <BallView>{score}</BallView>
                    </TableCell>
                  </Hidden>

                  <Hidden smUp>
                    <TableCell>
                      <Box textAlign="left" fontSize={14}>
                        <Grid container spacing={1} alignItems="center">
                          <Grid item xs={6}>
                            <Grid container>
                              <Grid item className="m_cell_title">
                                Дата начисления
                              </Grid>
                              <Grid item xs={12} className="m_cell">
                                <Moment format="DD.MM.YYYY">{created_at}</Moment>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={6}>
                            <Box fontSize={18}>
                              <BallView justify="flex-end">{score}</BallView>
                            </Box>
                          </Grid>

                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item className="m_cell_title">
                                Тип действия
                              </Grid>
                              <Grid item xs={12} className="m_cell">
                                {breeder_balance_category.title}
                                {owner && (
                                  <Box>
                                    <small>
                                      {owner?.last_name} <Phone>{owner?.phone}</Phone>
                                    </small>
                                  </Box>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </TableCell>
                  </Hidden>
                </TableRow>
              ))
          ) : (
            <TableRow>
              <TableCell colSpan={3}>Здесь будет информация о баллах</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {countArray(items) > perPage && (
        <Box pt={4} textAlign="center">
          <Pagination
            page={page}
            variant="text"
            onChange={handleChange}
            count={Math.ceil(countArray(items) / perPage)}
          />
        </Box>
      )}
    </>
  )
}

export default BallList
