import ReactDOM from "react-dom"
import { MuiThemeProvider } from "@material-ui/core"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import "moment/locale/ru"
import "./scss/app.sass"

import Moment from "react-moment"
import moment from "moment"

import store from "./store"
import { themeDefault } from "./theme"
import App from "./app"

moment.locale("ru")
moment.defaultFormat = "DD.MM.YYYY"
Moment.globalLocale = "ru"
Moment.globalFormat = "DD.MM.YYYY"

declare global {
  interface Window {
    dataLayer?: any
    location: Location
    APP_CONFIG?: any
  }
}
window.dataLayer = window.dataLayer || []

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={themeDefault}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("root")
)
