import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles(() => ({
  link: {
    zIndex: 1,
    position: "relative",
    display: "inline-block",
    textDecoration: "none!important",
    color: "#566169",
    borderBottom: "1px dashed #566169",
    fontWeight: 400,
    fontSize: 14
  },
  fieldContainer: {
    textAlign: "center"
  },
  socialLabel: {
    fontSize: "72%",
    textTransform: "none"
  }
}))

export default useStyles
