import Dialog from "../../components/dialog"
import { useDispatch, useSelector } from "../../hooks"
import { SET_PROPS } from "../../store/props/types"

export const modalName = "ViewerModal"

export const OpenViewer = (img: string, dispatch: any) => {
  dispatch({
    type: SET_PROPS,
    payload: {
      modal: {
        [modalName]: {
          show: true,
          image: img
        }
      }
    }
  })
}

const ViewerModal = () => {
  const dispatch = useDispatch()
  const { modal } = useSelector((state) => state.propsReducer)
  const { show = false, image } = modal[modalName] || {}

  const onClose = () => {
    dispatch({
      type: SET_PROPS,
      payload: {
        modal: {
          [modalName]: {
            show: false
          }
        }
      }
    })
  }

  return (
    <Dialog maxWidth="md" scroll="body" onCloseDialog={onClose} name={modalName} open={show}>
      <img src={image} alt="" style={{ maxWidth: "100%" }} />
    </Dialog>
  )
}

export default ViewerModal
