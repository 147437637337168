import {
  middle_name,
  name,
  phone,
  email,
  validation,
  invite_ref,
  address,
  date,
  string_required,
  nursery_recorder_id,
  boolean,
  boolean_required,
  imageDropzone
} from "../../components/form-control/validation"

const validationShema = [
  validation({
    last_name: name,
    first_name: name,
    middle_name
  }),
  validation({
    phone,
    email,
    invite_ref,
    address
  }),

  validation({
    nursery_name: string_required,
    image_file: imageDropzone,
    register_at: date,
    nursery_recorder_id,
    rules: boolean_required,
    subscribe_sms: boolean,
    subscribe_email: boolean
  })
]

export default validationShema
