import { forwardRef } from "react"
import { NavLink } from "react-router-dom"

export default forwardRef((props: any, ref: any) => {
  return (
    <NavLink
      ref={ref}
      {...props}
      isActive={(match, location) => {
        if (!match || !location) {
          return false
        }
        return match?.url === location?.pathname // && hash === hashLocation
      }}
      exact
      activeClassName="active"
    >
      {props?.children}
    </NavLink>
  )
})
