import { Route, Switch } from "react-router-dom"
import useReactRouter from "use-react-router"
import MainPage from "../page/main"
import PrivateRoute from "../components/route"

import LkPage from "../page/profile"
import SignoutPage from "../page/signout"
import { Error404Page } from "../page/error"
import Promo from "../page/promo"

const Routes = () => {
  const { location } = useReactRouter()

  return (
    <Switch location={location}>
      <Route key="index" path="/" render={() => <MainPage />} exact />
      <Route key="promo" path="/promo" render={() => <Promo />} exact />
      <PrivateRoute key="profile" path="/profile" component={LkPage} exact />
      <PrivateRoute key="signout" path="/signout" component={SignoutPage} exact />
      <Route key="notfound" path="*" exact component={Error404Page} />
    </Switch>
  )
}

export default Routes
