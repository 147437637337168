import { useState, ReactNode, useEffect } from "react"
import { Autocomplete } from "@material-ui/lab"
import { UseFormReturn, Controller } from "react-hook-form"
import { Box, TextField } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"

import { withPlanner, WithPlannerProps } from "../hoc"

type Props = {
  form: UseFormReturn<any>
  name: string
  label?: string
  placeholder?: string
  disabled?: boolean
  required?: boolean
  processed?: boolean
  optionIcon?: ReactNode
  noOptionsText?: string
  defaultOptions?: Option[]
  fetch?(val: string): Promise<Option[]>
  onchange?: any
}

export type Option = {
  key: any
  value: string | number
  title: string
  unrestricted_value: string | undefined
}

export const AutocompleteDadataInputNew: React.FC<Props & WithPlannerProps> = withPlanner((props): JSX.Element => {
  const { form, name, planner, label, placeholder, defaultOptions, fetch } = props

  const { control } = form

  const [options, setOptions] = useState<Option[]>(defaultOptions ?? [])
  const [loading, setLoading] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string | undefined>()

  const match = async (val: string): Promise<Option[]> => {
    return new Promise((resolve) => {
      if (defaultOptions) resolve(defaultOptions.filter(({ title }) => title.includes(val)))
    })
  }

  const handleFetch = async () => {
    if (inputValue && inputValue?.length > 2) {
      setLoading(true)
      setOptions([])
      const data = fetch ? await fetch(inputValue) : await match(inputValue)
      setOptions(data)
    } else if (defaultOptions) setOptions(defaultOptions)
  }

  useEffect(() => {
    if (planner?.clearTimeout()) {
      planner?.timeout(
        () => {
          handleFetch()
        },
        inputValue && inputValue?.indexOf(" д ") > 5 ? 10 : 1000
      )
    }
  }, [inputValue])

  useEffect(() => {
    console.log(options)
    setLoading(false)
  }, [options])

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          loading={loading}
          loadingText="Загрузка..."
          clearOnBlur={false}
          clearOnEscape={false}
          disabledItemsFocusable
          disableClearable
          getOptionLabel={(option) => {
            return option.title
          }}
          id="days-autocomplete"
          onChange={(_event, v) => {
            field.onChange(v)
          }}
          options={loading ? [] : options}
          filterOptions={(d, s) => {
            return d.filter(({ title }) => {
              return fetch ? true : title === s.inputValue
            })
          }}
          renderOption={({ title, unrestricted_value }: Option) => {
            return (
              <Box style={{ color: "#333", textAlign: "left" }} key={unrestricted_value}>
                {title}
              </Box>
            )
          }}
          renderInput={(params) => (
            <TextField
              color="primary"
              variant="filled"
              placeholder={placeholder}
              label={label ?? "Адрес доставки"}
              error={!!error}
              helperText={error?.message}
              name={name}
              type="text"
              onChange={(e) => {
                setInputValue(e.target.value)
              }}
              inputRef={ref}
              {...params}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: loading ? <CircularProgress color="inherit" size={20} /> : null
              }}
            />
          )}
        />
      )}
    />
  )
})
