import { Box, Grid, Typography } from "@material-ui/core"
// eslint-disable-next-line import/no-extraneous-dependencies
import classNames from "classnames"

const Error50xPage = () => {
  return (
    <Grid
      className={classNames("animate__animated", "animate__fadeIn")}
      container
      style={{ height: "100%", background: "url('/imgs/404-bg.png') repeat bottom center" }}
      alignItems="flex-end"
      alignContent="flex-end"
    >
      <Grid item xs={12} id="dog404">
        <Box textAlign="center" pt={{ xs: 8, md: 15 }}>
          <Box p={3}>
            <Box mb={6}>
              <img alt="AlphaPet" style={{ marginLeft: 15 }} src="/imgs/logo-black.svg" />
            </Box>
            <Typography variant="h4" color="secondary">
              УПС, ЧТО-ТО ПОШЛО НЕ ТАК!
            </Typography>
          </Box>
          <Box pb={4} px={3}>
            <Typography variant="body1" color="secondary">
              Мы уже работаем над этим.
            </Typography>
          </Box>
          <Box textAlign="center" pt={6} style={{ overflow: "hidden" }}>
            <img
              className="animated faster fadeInUp delay-3s"
              style={{ maxWidth: 700, width: "60%", minWidth: 200, display: "block", padding: 0, margin: "0 auto" }}
              src="/imgs/50x.png"
              alt=""
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Error50xPage
